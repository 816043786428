import React, { useState } from 'react';
import { Modal, Loading, Button } from '@carbon/react';
import { DownloadNotification, isDanger } from './DownloadNotification';
import { WarningAlt } from '@carbon/icons-react';
import { getClusterSnapshot, updateClusterSnapshot } from '@src/api';
import download from 'js-file-download';
import { getClusterShortId } from '@components/utils';

const DownloadModal = ({
  isOpened,
  onClose,
  setSaveStatus,
  nodeData = [],
  clusterConfig = {},
  clusterInfo = {}
}) => {
  const isDangerous = isDanger(clusterConfig, nodeData);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const clusterName = clusterInfo?.name || 'unknown';
  const clusterShortId = getClusterShortId(clusterInfo);

  const downloadFile = filename => {
    getClusterSnapshot(filename)
      .then(function (response) {
        setErrorMessage(null);
        download(response.data, `${clusterName}.zip`);
      })
      .catch(err => {
        setErrorMessage(
          `Download failure: Failed to download ${clusterName}.zip`
        );
        console.error(err);
      });
  };

  const handleDownloadClick = async () => {
    setIsLoading(true);
    setSaveStatus('active');
    const res = await updateClusterSnapshot({
      clusterInfo,
      clusterConfig,
      nodeData
    });

    setIsLoading(false);
    if (res.status !== 200) {
      const errData = res?.response?.data;
      setSaveStatus('error');
      setErrorMessage(
        'Failed to save snapshot: ' + (errData?.message || errData?.cause || '')
      );
      return;
    }
    setSaveStatus('finished');
    downloadFile(clusterShortId);
  };

  return (
    <Modal
      className="cube-download-modal"
      open={isOpened}
      modalHeading="Download Cluster Snapshot"
      passiveModal
      onRequestClose={() => onClose(false)}
    >
      <Loading active={isLoading} withOverlay />
      <p>
        This action will download a compressed file named
        <strong> {clusterName}.zip </strong>
        containing all nodes snapshot.
      </p>
      <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} />
      <p
        style={{
          marginTop: '0.5rem',
          marginBottom: '1rem'
        }}
      >
        Here is an overview of the cluster configuration.
      </p>
      <DownloadNotification
        clusterConfig={clusterConfig}
        tableData={nodeData}
      />
      {isDangerous && (
        <div className="download-description-container">
          <WarningAlt />
          <div className="download-description">
            <span>
              When there are errors shown above, the cluster may not function
              properly.
            </span>
            <span>
              Please resolve the errors before downloading the snapshot.
            </span>
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="download-description-container">
          <WarningAlt />
          <div className="download-description">{errorMessage}</div>
        </div>
      )}
      <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} />
      <Button
        className="download-modal-button"
        disabled={isDangerous}
        onClick={handleDownloadClick}
        aria-label="Download"
        data-modal-primary-focus
      >
        Download
      </Button>
    </Modal>
  );
};

export default DownloadModal;
