import React, { useState, useMemo } from 'react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import { NumberInput, TextInput, Dropdown } from '@carbon/react';
import PropTypes from 'prop-types';
import { isIFSelectable, gatewayRules } from './utils';
import CreateFormLabel from '@components/CreateFormLabel';
import NetworkTable from './NetworkTable';
import { isEmpty } from 'lodash';

const NetworkStep = ({
  initIFs,
  bondIFs,
  vlanIFs,
  defaultIF,
  defaultGateway,
  handleInitIFResize,
  handleCreateBond,
  handleRemoveIFs,
  handleCreateVLAN,
  handleIFChange,
  handleDefaultIFChange,
  handleDefaultGatewayChange
}) => {
  const [editID, setEditID] = useState(undefined);
  const [editKey, setEditKey] = useState(undefined);
  const [isEditValid, setIsEditValid] = useState(true);

  const IFs = useMemo(
    () => [...initIFs, ...bondIFs, ...vlanIFs],
    [initIFs, bondIFs, vlanIFs]
  );

  const defaultIFDetail = IFs.find(
    IF => IF.id === defaultIF.id && isIFSelectable(IF)
  );

  const isDefaultIFValid = !!defaultIFDetail;

  const isDefaultGatewayValid = gatewayRules.every(rule =>
    rule.validate(defaultGateway, defaultIFDetail)
  );

  const defaultGatewayInvalidText =
    gatewayRules.find(rule => !rule.validate(defaultGateway, defaultIFDetail))
      ?.text || '';

  const IFidtoName = IFid => IFs.find(row => row.id === IFid)?.name || 'None';

  return (
    <CreateTearsheetStep
      title="Configure Network Interfaces"
      hasFieldset={false}
      disableSubmit={
        !isEditValid || !isDefaultIFValid || !isDefaultGatewayValid
      }
    >
      <ul>
        <li className="network-ul-item">
          <NumberInput
            allowEmpty={false}
            id="network-num-input"
            min={1}
            max={100}
            value={initIFs.length}
            label="Number of network interfaces"
            invalidText="It should be between 1 and 100"
            onChange={(event, { value }) => {
              if (1 <= value && value <= 100) {
                const removeIDs = handleInitIFResize(value);
                if (removeIDs.includes(editID)) {
                  setEditID(undefined);
                  setEditKey(undefined);
                  setIsEditValid(true);
                }
              }
            }}
          />
        </li>
        <li className="network-ul-item">
          <NetworkTable
            IFs={IFs}
            editID={editID}
            setEditID={setEditID}
            editKey={editKey}
            setEditKey={setEditKey}
            isEditValid={isEditValid}
            setIsEditValid={setIsEditValid}
            handleCreateBond={handleCreateBond}
            handleRemoveIFs={handleRemoveIFs}
            handleCreateVLAN={handleCreateVLAN}
            handleIFChange={handleIFChange}
          />
        </li>
        <li className="network-ul-item">
          <Dropdown
            id="network-dropdown"
            titleText={
              <CreateFormLabel
                label="Select the default network interface"
                isRequired={true}
                infoLabel="The interface must be enabled and the IP address must be specified."
              />
            }
            label="Select default network interface"
            items={IFs.filter(IF => isIFSelectable(IF))}
            onChange={({ selectedItem }) => {
              handleDefaultIFChange(selectedItem);
            }}
            selectedItem={isEmpty(defaultIF) ? null : defaultIF}
            itemToString={item => IFidtoName(item.id)}
          />
        </li>
        <li className="network-ul-item">
          <TextInput
            id="network-gateway-input"
            labelText={
              <CreateFormLabel
                label="Set the default gateway ip address"
                isRequired={true}
              />
            }
            value={defaultGateway}
            invalid={!isDefaultGatewayValid}
            onChange={evt => {
              const text = evt.target.value;
              handleDefaultGatewayChange(text);
            }}
            invalidText={defaultGatewayInvalidText}
          />
        </li>
      </ul>
    </CreateTearsheetStep>
  );
};

NetworkStep.propTypes = {
  initIFs: PropTypes.array.isRequired,
  bondIFs: PropTypes.array.isRequired,
  vlanIFs: PropTypes.array.isRequired,
  defaultIF: PropTypes.object.isRequired,
  defaultGateway: PropTypes.string.isRequired,
  handleInitIFResize: PropTypes.func.isRequired,
  handleCreateBond: PropTypes.func.isRequired,
  handleRemoveIFs: PropTypes.func.isRequired,
  handleCreateVLAN: PropTypes.func.isRequired,
  handleIFChange: PropTypes.func.isRequired,
  handleDefaultIFChange: PropTypes.func.isRequired,
  handleDefaultGatewayChange: PropTypes.func.isRequired
};

export default NetworkStep;
