import ipaddr from 'ipaddr.js';

const defaultClusterRoleSettings = {
  extIP: '',
  region: 'RegionOne',
  secretSeed: '',
  mgmtCIDR: '10.254.0.0/16'
};

export const prompts = {
  extIP: {
    label: 'Set external IP/domain (optional)'
  },
  region: {
    label: 'Set region',
    isRequired: true
  },
  secretSeed: {
    label: 'Set cluster secret seed',
    isRequired: true
  },
  mgmtCIDR: {
    label: 'Set management CIDR',
    isRequired: true
  }
};

export const placeholders = {
  extIP: 'Set external IP/domain',
  region: 'Set region',
  secretSeed: 'Set cluster secret seed',
  mgmtCIDR: 'Set management CIDR'
};

const secretSeedRules = [
  {
    text: 'This should be comprised of 3~63 characters',
    validate: (str = '') => 3 <= str.length && str.length <= 63
  },
  {
    text: 'This should only compose by "A-Z", "a-z" or "0-9"',
    validate: str => /^[0-9A-Za-z]*$/.test(str)
  }
];

export const rules = {
  extIP: [
    {
      text: 'This should be a valid IPv4 address',
      validate: (str = '') =>
        str === '' || ipaddr.IPv4.isValidFourPartDecimal(str)
    }
  ],
  region: [{ text: '', validate: () => true }],
  secretSeed: secretSeedRules,
  mgmtCIDR: [
    {
      text: 'IPv4 CIDR address should contain "/".',
      validate: (str = '') => /\//.test(str)
    },
    {
      text: 'This should be a valid IPv4 CIDR address',
      validate: str => {
        const substr = str.split('/');
        let mask = Number(substr[1]);
        return (
          substr.length === 2 &&
          ipaddr.IPv4.isValidFourPartDecimal(substr[0]) &&
          !isNaN(mask) &&
          1 <= mask &&
          mask <= 32
        );
      }
    }
  ]
};

export const options = Object.keys(defaultClusterRoleSettings);
