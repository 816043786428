import React, { useEffect, useState } from 'react';
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableHeader,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  Button,
  TableToolbarSearch,
  Pagination
} from '@carbon/react';
import { TrashCan, Edit, Copy, Cube, CloudDownload } from '@carbon/icons-react';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Add } from '@carbon/icons-react';

const headers = [
  {
    header: 'ID',
    key: 'id'
  },
  {
    header: 'HOSTNAME',
    key: 'hostname'
  },
  {
    header: 'ROLE',
    key: 'role'
  },
  {
    header: 'MANAGEMENT IP',
    key: 'mgmtIP'
  },
  {
    header: 'DEFAULT NETWORK IP',
    key: 'defaultIP'
  },
  {
    header: 'OPERATIONS',
    key: 'operations'
  }
];
const ConfigTable = ({
  rowData,
  isSaved,
  setWizardOpen,
  setGetSnapshotModalOpen,
  setTableData,
  setSelectedRowId,
  setWizardId
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(5);

  useEffect(() => {
    // update currentPage upon deletion
    if (
      currentPage > 1 &&
      (currentPage - 1) * currentPageSize >= rowData.length
    ) {
      let newPage = currentPage;
      while (newPage > 1 && (newPage - 1) * currentPageSize >= rowData.length)
        newPage--;
      setCurrentPage(newPage);
    }
  }, [rowData, currentPage, currentPageSize]);

  const firstRowIndex = (currentPage - 1) * currentPageSize;

  const tableRowData = rowData.map(config => {
    const IFs = [...config.initIFs, ...config.bondIFs, ...config.vlanIFs];
    const mgmtIFId = config.roleSettings.mgmtIF.id;
    const mgmtIP = IFs.find(IF => IF.id === mgmtIFId)?.IPAddr || 'None';
    const defaultIFId = config.defaultIF.id;
    const defaultIP = IFs.find(IF => IF.id === defaultIFId)?.IPAddr || 'None';
    return {
      ...config,
      mgmtIP,
      defaultIP,
      operations: config.hostname // to identify hostname from config table buttons
    };
  });

  const handleEditBtnClick = selectedRow => {
    setWizardOpen(true);
    setWizardId(selectedRow.id);
    setSelectedRowId(selectedRow.id);
  };

  const handleGetSnapshotBtnClick = selectedRow => {
    setGetSnapshotModalOpen(true);
    setSelectedRowId(selectedRow.id);
  };

  const handleCopyBtnClick = selectedRows => {
    setTableData(prevData => {
      const oldConfig = cloneDeep(prevData);
      const selectedRowData = selectedRows.map(
        row => rowData.find(r => r.id === row.id) || {}
      );
      selectedRowData.forEach(row => {
        row.id = uuidv4();
        row.hostname = `${row.hostname}-copy`;
      });
      return [...oldConfig, ...selectedRowData];
    });
  };

  const handleDeleteBtnClick = selectedRows => {
    setTableData(prevData => {
      let newConfig = cloneDeep(prevData);
      for (let i = 0; i < selectedRows.length; i++) {
        newConfig = newConfig.filter(
          config => config.id !== selectedRows[i].id
        );
      }
      return [...newConfig];
    });
  };

  const renderCellContext = cell => {
    if (cell.info.header === 'operations') {
      const rowId = cell.id.split(':')[0];
      const selectedRow = [{ id: rowId }];
      const renderContent = (
        <TableCell key={cell.id}>
          <div className="configTable-operations-wrapper">
            <Button
              hasIconOnly
              renderIcon={Edit}
              iconDescription="Edit Node"
              onClick={() => handleEditBtnClick(selectedRow[0])}
              kind="ghost"
              size="sm"
              data-test={`edit-${cell.value}`}
            />
            <Button
              hasIconOnly
              renderIcon={Copy}
              iconDescription="Copy Node"
              onClick={() => handleCopyBtnClick(selectedRow)}
              kind="ghost"
              size="sm"
              data-test={`copy-${cell.value}`}
            />
            <Button
              hasIconOnly
              renderIcon={CloudDownload}
              iconDescription="Get Node Snapshot URL"
              onClick={() => handleGetSnapshotBtnClick(selectedRow[0])}
              disabled={!isSaved}
              kind="ghost"
              size="sm"
              data-test={`get-snapshot-${cell.value}`}
            />
            <Button
              hasIconOnly
              renderIcon={TrashCan}
              iconDescription="Remove Node"
              onClick={() => handleDeleteBtnClick(selectedRow)}
              kind="ghost"
              size="sm"
              data-test={`remove-${cell.value}`}
            />
          </div>
        </TableCell>
      );
      return renderContent;
    }
    return (
      <TableCell
        key={cell.id}
        className={`config-table-tr-${cell.info.header}`}
      >
        {cell.info.header === 'id' ? (
          <Cube size={20} aria-label={cell.value} />
        ) : (
          cell.value
        )}
      </TableCell>
    );
  };

  const filterRowsFunc = ({ inputValue }) => {
    const inputLowercase = inputValue.toLowerCase();
    const isMatched = text => text.toLowerCase().includes(inputLowercase);

    return tableRowData
      .filter(
        row =>
          isMatched(row.hostname) ||
          isMatched(row.role) ||
          isMatched(row.mgmtIP) ||
          isMatched(row.defaultIP)
      )
      .map(row => row.id);
  };

  return (
    <div className="configTable">
      <DataTable
        headers={headers}
        rows={tableRowData}
        className="config-data-table"
        size="xl"
        filterRows={filterRowsFunc}
        render={({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          onInputChange
        }) => {
          return (
            <TableContainer>
              <TableToolbar>
                <TableToolbarContent>
                  <TableToolbarSearch
                    placeholder="Search node"
                    expanded
                    onChange={e => {
                      onInputChange(e);
                      setCurrentPage(1);
                    }}
                  />
                  <Button
                    renderIcon={Add}
                    onClick={() => {
                      const newID = uuidv4();
                      setWizardId(newID); // set a new id for the wizard when add node
                      setSelectedRowId(null);
                      setWizardOpen(true);
                    }}
                    kind="ghost"
                  >
                    Add Node
                  </Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table>
                <TableHead>
                  <TableRow>
                    {headers.map((header, i) => (
                      <TableHeader
                        key={i}
                        {...getHeaderProps({
                          header,
                          isSortable: !['id', 'operations'].includes(header.key)
                        })}
                        className={`config-table-th-${header.key}`}
                      >
                        {!['id', 'operations'].includes(header.key) &&
                          header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(firstRowIndex, firstRowIndex + currentPageSize)
                    .map((row, i) => (
                      <React.Fragment key={i}>
                        <TableRow key={i} {...getRowProps({ row })}>
                          {row.cells.map(cell => renderCellContext(cell))}
                        </TableRow>
                      </React.Fragment>
                    ))}
                </TableBody>
              </Table>
              {rowData.length === 0 && (
                <div className="configTable-empty-description">
                  <div style={{ margin: '1rem' }}>
                    <strong>Looks like the cluster is empty now</strong>
                  </div>
                  <Button
                    renderIcon={Add}
                    onClick={() => {
                      const newID = uuidv4();
                      setWizardId(newID); // set a new id for the wizard when add node
                      setSelectedRowId(null);
                      setWizardOpen(true);
                    }}
                    kind="ghost"
                  >
                    Add Node
                  </Button>
                </div>
              )}
              {rowData.length > 0 && rows.length === 0 && (
                <div className="configTable-empty-description">
                  <strong>
                    Sorry, we cannot find anything that matches your search.
                  </strong>
                </div>
              )}
            </TableContainer>
          );
        }}
      />
      <Pagination
        totalItems={rowData.length}
        backwardText="Previous page"
        forwardText="Next page"
        page={currentPage}
        pageSize={currentPageSize}
        pageSizes={[5, 10, 15, 25]}
        itemsPerPageText="Items per page"
        onChange={({ page, pageSize }) => {
          if (pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
          }
          setCurrentPage(page);
        }}
      />
    </div>
  );
};

export default ConfigTable;
