import React from 'react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import { ComboBox } from '@carbon/react';
import { TimezonePicker, offsetToString, timezoneInfo } from './TimezonePicker';
import './TimezonePicker/styles.css';
import PropTypes from 'prop-types';

const infoToString = info =>
  info === null ? null : offsetToString(info.offset) + ' ' + info.name;

const TimezoneStep = ({ timezone, handleTimezoneChange }) => {
  return (
    <CreateTearsheetStep
      title="Choose the Time Zone of CubeOS"
      disableSubmit={timezone === null}
      hasFieldset={false}
    >
      <ComboBox
        id="timezone-combo-box"
        label="lg"
        placeholder="Select a timezone"
        items={timezoneInfo}
        selectedItem={timezone}
        itemToString={item => infoToString(item)}
        onChange={({ selectedItem }) => {
          handleTimezoneChange(selectedItem);
        }}
      />
      <TimezonePicker
        timezone={timezone}
        handleTimezoneChange={handleTimezoneChange}
      />
    </CreateTearsheetStep>
  );
};

TimezoneStep.propTypes = {
  timezone: PropTypes.object,
  handleTimezoneChange: PropTypes.func.isRequired
};

export default TimezoneStep;
