import React, { useState } from 'react';
import {
  TextInput,
  Button,
  DataTable,
  Table,
  TableContainer,
  TableSelectRow,
  TableSelectAll,
  TableToolbar,
  TableToolbarSearch,
  TableBatchActions,
  TableBatchAction,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Link as LinkComponent,
  Toggle,
  Stack,
  Tooltip
} from '@carbon/react';
import { Vlan, TrashCan } from '@carbon/icons-react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import {
  headers,
  isIFBonded,
  IPMasks,
  nameRules,
  IPAddrRules,
  IPMaskRules,
  vlanIDRules
} from './utils';
import { validate as isUuid } from 'uuid';

const NetworkTable = ({
  IFs,
  editID,
  setEditID,
  editKey,
  setEditKey,
  isEditValid,
  setIsEditValid,
  handleCreateBond,
  handleRemoveIFs,
  handleCreateVLAN,
  handleIFChange
}) => {
  const [editInvalidText, setEditInvalidText] = useState('');

  const cellDataToStr = data =>
    Array.isArray(data)
      ? data.map(ele => cellElementToStr(ele)).join(',\n')
      : cellElementToStr(data);

  const cellElementToStr = ele => {
    if (typeof ele !== 'string') return ele;
    return isUuid(ele) ? IFidtoName(ele) : ele;
  };

  const renderRowContent = row => {
    const IF = IFs.find(_IF => _IF.id === row.id);
    const showConfigureIP =
      IF.enabled && !IF.IPAddr && !IF.IPMask && !isIFBonded(IF);

    return row.cells.map(cell => {
      const key = cell.info.header;
      const isEdit = editID === row.id && editKey === cell.info.header;

      let canEdit = false;
      if (IF.type === 'bond' && key === 'name') canEdit = true; // edit name
      if (IF.type === 'vlan' && key === 'vlanID') canEdit = true; // edit vlanID
      if (
        IF.enabled &&
        ['IPAddr', 'IPMask'].includes(key) &&
        !isIFBonded(IF) &&
        !showConfigureIP
      )
        canEdit = true; // edit IPAddr and IPMask

      let renderedContent = <></>;
      if (key === 'others') {
        const canCreateVlan = !isIFBonded(IF) && IF.type !== 'vlan';
        const canRemove = IF.type !== 'init';

        renderedContent = (
          <Stack orientation="horizontal" className="table-others">
            <Button
              hasIconOnly
              disabled={!canCreateVlan}
              className={canCreateVlan ? undefined : 'hidden-button'}
              renderIcon={Vlan}
              iconDescription="Create VLAN"
              onClick={() => {
                if (!canCreateVlan || !isEditValid) return;
                const newID = uuidv4();
                handleCreateVLAN(IF, newID);
                setEditID(newID);
                setEditKey('vlanID');
              }}
              kind="ghost"
              size="sm"
              data-test={`create-vlan-${IFidtoName(row.id)}`}
            />
            <Button
              hasIconOnly
              disabled={!canRemove}
              className={canRemove ? undefined : 'hidden-button'}
              renderIcon={TrashCan}
              iconDescription="Remove"
              onClick={() => {
                const removeIDs = handleRemoveIFs([IF.id]);
                if (removeIDs.includes(editID)) {
                  setEditID(undefined);
                  setEditKey(undefined);
                  setIsEditValid(true);
                }
              }}
              kind="ghost"
              size="sm"
              data-test={`remove-${IFidtoName(row.id)}`}
            />
          </Stack>
        );
      } else if (showConfigureIP && key === 'IPAddr') {
        renderedContent = (
          <LinkComponent
            className="editable"
            id={cell.id}
            onClick={() => {
              if (!isEditValid) return;
              setEditID(IF.id);
              setEditKey('IPAddr');
              let offset = 0;
              while (
                offset < 255 &&
                !IPAddrRules(IFs, editID).every(rule =>
                  rule.validate(`192.168.${offset}.1`)
                )
              )
                offset++;

              handleIFChange(IF, {
                IPAddr: `192.168.${offset}.1`,
                IPMask: '255.255.255.0'
              });
            }}
          >
            Configure IP
          </LinkComponent>
        );
      } else if (key === 'enabled') {
        renderedContent = (
          <Toggle
            size="sm"
            id={cell.id}
            key={cell.id}
            toggled={cell.value}
            labelA=""
            labelB=""
            onToggle={toggled => {
              if (editID === row.id) {
                setEditID(undefined);
                setEditKey(undefined);
                setIsEditValid(true);
              }
              handleIFChange(IF, {
                [key]: toggled,
                IPAddr: undefined,
                IPMask: undefined
              });
            }}
            disabled={IF.type === 'init' && IF.master !== undefined}
          />
        );
      } else if (isEdit) {
        const msg = {
            vlanID: 'Enter vlan ID',
            name: 'Enter interface name',
            IPAddr: 'Enter IP address',
            IPMask: 'Enter IP mask'
          },
          rules = {
            vlanID: vlanIDRules(IFs, editID),
            name: nameRules(IFs, editID),
            IPAddr: IPAddrRules(IFs, editID),
            IPMask: IPMaskRules
          };

        const handleEditSave = evt => {
          let text = evt.target.value;
          if (isEditValid) {
            switch (key) {
              case 'vlanID':
                handleIFChange(IF, {
                  [key]: Number(text)
                });
                break;

              case 'IPMask':
                handleIFChange(IF, {
                  [key]: IPMasks[text] || text
                });
                break;

              default:
                handleIFChange(IF, {
                  [key]: text
                });
            }
            setEditID(undefined);
            setEditKey(undefined);
          }
        };

        const updateValidStatus = evt => {
          const text = evt.target.value;
          setIsEditValid(rules[key].every(rule => rule.validate(text)));
          setEditInvalidText(
            rules[key].find(rule => !rule.validate(text))?.text || ''
          );
        };

        const editInputProps = {
          labelText: '',
          hideLabel: true,
          id: cell.id,
          ref: inp => document.activeElement !== inp && inp?.focus(),
          placeholder: msg[editKey],
          defaultValue: IF[key],
          onBlur: evt => handleEditSave(evt),
          onKeyDown: evt => evt.key === 'Enter' && handleEditSave(evt),
          onChange: evt => updateValidStatus(evt),
          onFocus: evt => updateValidStatus(evt),
          className: 'edit-input',
          invalid: !isEditValid,
          invalidText: editInvalidText
        };
        if (editKey === 'name') {
          const tooltipLabel = (
            <>
              <strong> Naming conventions: </strong>
              <ul>
                <li>
                  <strong>mgmt</strong>: usually used for management.
                </li>
                <li>
                  <strong>data</strong>: usually used as overlay interface.
                </li>
                <li>
                  <strong>stor</strong>: usually used for cube storage.
                </li>
                <li>
                  <strong>ext</strong>: usually used for internet connection.
                </li>
              </ul>
            </>
          );
          renderedContent = (
            <Tooltip align="top-left" leaveDelayMs={100} label={tooltipLabel}>
              <TextInput {...editInputProps} />
            </Tooltip>
          );
        } else {
          renderedContent = <TextInput {...editInputProps} />;
        }
      } else {
        renderedContent = cellDataToStr(cell.value);
      }
      return (
        <TableCell
          key={cell.id}
          onClick={() => {
            if (!canEdit || !isEditValid) return;
            setEditID(row.id);
            setEditKey(cell.info.header);
          }}
          data-test={`cell-${IFidtoName(row.id)}-${cell.info.header}`}
        >
          {canEdit && !(editID === row.id && editKey === key) ? (
            <LinkComponent className="editable">
              {renderedContent}
            </LinkComponent>
          ) : (
            renderedContent
          )}
        </TableCell>
      );
    });
  };

  const IFidtoName = IFid => IFs.find(row => row.id === IFid)?.name || 'None';

  const isBondable = IFid => {
    const IF = IFs.find(_IF => _IF.id === IFid);
    return IF && IF.type === 'init' && !IF.master;
  };

  const isInit = IFid => {
    const IF = IFs.find(_IF => _IF.id === IFid);
    return IF && IF.type === 'init';
  };

  const filterRowsFunc = ({ inputValue }) => {
    const inputLowercase = inputValue.toLowerCase();
    return IFs.filter(
      IF =>
        IF.name.toLowerCase().includes(inputLowercase) ||
        IF.type.toLowerCase().includes(inputLowercase) ||
        (IF.IPMask && IF.IPMask.includes(inputLowercase)) ||
        (IF.IPAddr && IF.IPAddr.includes(inputLowercase))
    ).map(IF => IF.id);
  };

  return (
    <DataTable
      rows={IFs}
      headers={headers}
      size="sm"
      filterRows={filterRowsFunc}
    >
      {({
        rows,
        headers,
        getTableProps,
        onInputChange,
        getHeaderProps,
        getSelectionProps,
        selectedRows,
        getBatchActionProps
      }) => (
        <TableContainer className="network-table">
          <TableToolbar>
            <TableToolbarSearch
              placeholder="Search"
              expanded
              onChange={evt => onInputChange(evt)}
              style={{ backgroundColor: 'white' }}
              data-test="network-table-search"
            />
            {isEditValid && (
              <TableBatchActions
                className="node-network-table-batch-action"
                {...getBatchActionProps()}
              >
                {selectedRows.length > 1 &&
                  selectedRows.every(row => isBondable(row.id)) && (
                    <TableBatchAction
                      onClick={() => {
                        const newID = uuidv4();
                        handleCreateBond(
                          selectedRows.map(row => row.id),
                          newID
                        );
                        setEditID(newID);
                        setEditKey('name');

                        getBatchActionProps().onCancel();
                      }}
                    >
                      Create Bonding
                    </TableBatchAction>
                  )}
                {selectedRows.length === 1 && (
                  <span style={{ color: 'white', marginRight: '0.5rem' }}>
                    You may select multiple init interfaces to bond them
                  </span>
                )}
              </TableBatchActions>
            )}
          </TableToolbar>
          <Table {...getTableProps()} size="md">
            <TableHead>
              <TableRow>
                <TableSelectAll
                  {...getSelectionProps()}
                  className="hidden-checkbox"
                />

                {headers.map(header => (
                  <TableHeader
                    {...getHeaderProps({ header })}
                    key={header.key}
                    className={`table-header-${header.key}`}
                  >
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.id} data-test={`row-${IFidtoName(row.id)}`}>
                  <TableSelectRow
                    {...getSelectionProps({ row })}
                    disabled={!isBondable(row.id)}
                    className={isInit(row.id) ? undefined : 'hidden-checkbox'}
                  />
                  {renderRowContent(row)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {rows.length === 0 && (
            <div className="network-table-empty-description">
              <strong>
                Sorry, we cannot find anything that matches your search.
              </strong>
            </div>
          )}
        </TableContainer>
      )}
    </DataTable>
  );
};

NetworkTable.propTypes = {
  IFs: PropTypes.array.isRequired,
  editID: PropTypes.string,
  setEditID: PropTypes.func.isRequired,
  editKey: PropTypes.string,
  setEditKey: PropTypes.func.isRequired,
  isEditValid: PropTypes.bool.isRequired,
  setIsEditValid: PropTypes.func.isRequired,
  handleCreateBond: PropTypes.func.isRequired,
  handleRemoveIFs: PropTypes.func.isRequired,
  handleCreateVLAN: PropTypes.func.isRequired,
  handleIFChange: PropTypes.func.isRequired
};

export default NetworkTable;
