import React from 'react';
import '@src/index.scss';
import '@carbon/charts-react/styles.css';
import '@src/components/override';

import { createRoot } from 'react-dom/client';
import App from '@src/App';
import { BrowserRouter, Route } from 'react-router-dom';

createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <Route path="/">
      <App />
    </Route>
  </BrowserRouter>
);
