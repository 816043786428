const roleColors = {
  control: '#6929C4',
  compute: '#1192E8',
  storage: '#005d5d',
  'control-converged': '#9F1853',
  'edge-core': '#FA4D56',
  moderator: '#520408'
};

export { roleColors };
