import React, { useState } from 'react';
import { OrderedList, ListItem, TextInput, Button, Link } from '@carbon/react';
import ClusterWizard from '@components/ClusterWizard';
import ImportModal from '@components/ImportModal';
import { v4 as uuidv4 } from 'uuid';
import { Add, EdgeCluster, Edit, TrashCan } from '@carbon/icons-react';
import { ProductiveCard } from '@carbon/ibm-products/lib/components';
import { roleOptions } from '@components/NodeWizard/components/NodeRoleStep';
import { MeterChart } from '@carbon/charts-react';
import { roleColors } from '@content/utils';

const LandingPage = ({
  isCreateCluster,
  setIsCreateCluster,
  handleCreateCluster,
  handleEditInfo,
  handleDeleteCluster,
  handleImportCluster,
  clustersInfo,
  setCurrentId
}) => {
  const [editID, setEditID] = useState(undefined);
  const [importModelOpen, setImportModelOpen] = useState(false);
  const wizardId = uuidv4();

  return (
    <div className="landing-page">
      <div className="landing-page__banner">
        <h1 className="landing-page__heading">
          Welcome to Cube Snapshot Generator
        </h1>
      </div>
      <div className="landing-page__r2">
        <h2 className="landing-page__subheading">What is this tool ?</h2>
        <p className="landing-page__p">
          Cube Snapshot Generator is a web tool help you quick generate a Cube
          OS cluster snapshot.
        </p>
      </div>
      <div className="landing-page__r2">
        <h2 className="landing-page__subheading">How to use this tool ?</h2>
        <div className="landing-page__p landing-page__usage">
          <OrderedList>
            <ListItem className="landing-page__p">
              Click <strong>Create new cluster</strong> button to create new
              cluster by using cluster wizard.
            </ListItem>
            <ListItem className="landing-page__p">
              Follow the instructions in cluster wizard, you should be able to
              finish the common settings for a cluster.
            </ListItem>
            <ListItem className="landing-page__p">
              Click <strong>Add Node</strong> button to add node settings by
              using node wizard.
            </ListItem>
            <ListItem className="landing-page__p">
              Click <strong>Download</strong> to download configured cluster
              snapshot or click <strong>Save</strong> to get snapshot URL.
            </ListItem>
          </OrderedList>
        </div>
      </div>
      <div className="landing-page__r2">
        <h2 className="landing-page__subheading">
          How to apply snapshot on Cube OS ?
        </h2>
        <div className="landing-page__p">
          On Cube OS, select <strong>Advanced</strong> &gt;{' '}
          <strong>snapshot</strong> &gt; <strong>pull</strong>. Then you may
          apply the snapshot from either USB or NFS.
        </div>
      </div>
      <hr style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }} />
      <div className="landing-page__r2">
        <h2 className="landing-page__subheading">
          Clusters ({clustersInfo.length})
        </h2>
        <div className="cluster-cards">
          {clustersInfo.map((info, index) => {
            const tableData =
              JSON.parse(localStorage.getItem(`${info.id}-nodes`)) || [];

            const chartData = Object.keys(roleOptions).reduce((arr, role) => {
              arr.push({
                group: role,
                value: tableData.filter(config => config.role == role).length
              });
              return arr;
            }, []);

            const handleClusterNameSave = evt => {
              const rawName = evt.target.value;
              const name = rawName
                .replace(/^\.+/, '')
                .replaceAll('/', '')
                .replaceAll(/[?*\\"]/gi, '_')
                .replace(/^\s*$/, `Cluster ${index + 1}`)
                .substr(0, 20);
              handleEditInfo({ ...info, name });
              setEditID(undefined);
              evt.stopPropagation();
            };

            return (
              <ProductiveCard
                actionIcons={[
                  {
                    icon: Edit,
                    iconDescription: 'Edit Name',
                    id: `landing-page-edit-${info.id}`,
                    'data-test': `edit-${info.name}`,
                    onClick: evt => {
                      setEditID(info.id);
                      evt.stopPropagation();
                    }
                  },
                  {
                    icon: TrashCan,
                    iconDescription: 'Delete',
                    id: `landing-page-delete-${info.id}`,
                    'data-test': `delete-${info.name}`,
                    onClick: evt => {
                      handleDeleteCluster(info.id);
                      evt.stopPropagation();
                    }
                  }
                ]}
                key={info.id}
                className="cluster-card cluster-info-card"
                title={
                  <>
                    <EdgeCluster />
                    {editID === info.id ? (
                      <TextInput
                        labelText=""
                        id={`cluster-name-input-${info.id}`}
                        ref={inp =>
                          document.activeElement !== inp && inp?.focus()
                        }
                        placeholder="Enter cluster name"
                        defaultValue={info.name || ''}
                        onBlur={evt => handleClusterNameSave(evt)}
                        onKeyDown={evt =>
                          evt.key === 'Enter' && handleClusterNameSave(evt)
                        }
                        onClick={evt => evt.stopPropagation()}
                        className="cluster-name-input"
                      />
                    ) : (
                      <span className="cluster-card-title">
                        {info?.name || 'unknown'}
                      </span>
                    )}
                  </>
                }
                onClick={() => !editID && setCurrentId(info.id)}
              >
                <MeterChart
                  data={chartData}
                  options={{
                    getFillColor: field => roleColors[field],
                    height: '8rem',
                    toolbar: false,
                    meter: {
                      proportional: {
                        total: tableData.length === 0 || undefined,
                        unit: 'nodes',
                        totalFormatter: () => '',
                        breakdownFormatter: _ => `${_.datasetsTotal} nodes`
                      }
                    }
                  }}
                />
              </ProductiveCard>
            );
          })}
          <ProductiveCard
            className="cluster-card cluster-create-card"
            title={
              <>
                <Add />
                <span className="cluster-card-title">Create Cluster</span>
              </>
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '0.7rem'
              }}
            >
              <Button
                className="create-cluster-button"
                kind="tertiary"
                onClick={() => setIsCreateCluster(true)}
              >
                Create new cluster
              </Button>
              <div style={{ marginLeft: '2rem' }}>
                or{' '}
                <Link
                  onClick={() => setImportModelOpen(true)}
                  style={{ cursor: 'pointer' }}
                >
                  import cluster
                </Link>
              </div>
            </div>
          </ProductiveCard>
        </div>
      </div>
      <ClusterWizard
        key={`${wizardId}-cluster`}
        isOpened={isCreateCluster}
        onClose={() => {
          if (isCreateCluster) setIsCreateCluster(false);
        }}
        onSubmit={handleCreateCluster}
      />
      <ImportModal
        key={`${wizardId}-import`}
        isOpened={importModelOpen}
        onClose={setImportModelOpen}
        handleImportCluster={handleImportCluster}
      />
    </div>
  );
};

export default LandingPage;
