import ipaddr from 'ipaddr.js';
import { isEmpty } from 'lodash';

export const headers = [
  {
    key: 'name',
    header: 'NAME'
  },
  {
    key: 'type',
    header: 'TYPE'
  },
  {
    key: 'master',
    header: 'MASTER'
  },
  {
    key: 'slaves',
    header: 'SLAVES'
  },
  {
    key: 'vlanID',
    header: 'VLAN ID'
  },
  {
    key: 'IPAddr',
    header: 'IP ADDRESS'
  },
  {
    key: 'IPMask',
    header: 'IP MASK'
  },
  {
    key: 'enabled',
    header: 'ENABLED'
  },
  {
    key: 'others',
    header: ''
  }
];

export const IPMasks = Array.from(
  { length: 32 },
  (val, index) => 1 + index
).reduce((dict, val) => {
  return {
    ...dict,
    [`${val}`]: ipaddr.IPv4.subnetMaskFromPrefixLength(val).toString()
  };
}, {});

export const isIFBonded = IF => IF.type === 'init' && IF.master !== undefined;

const isIFEnabled = IF => IF.enabled;

const isIFWithIP = IF => IF.IPAddr !== undefined;

export const isIFSelectable = (IF, requireIP = true) =>
  // An IF is selectable if it's enabled and it is not bonded.
  isIFEnabled(IF) && !isIFBonded(IF) && (!requireIP || isIFWithIP(IF));

export const nameRules = (IFs, editID) => [
  {
    text: 'The name should only composed by lowercase letters',
    validate: (str = '') => /^[a-z]*$/.test(str)
  },
  {
    text: 'The name should not contain `bond`',
    validate: (str = '') => !/(bond)/.test(str)
  },
  {
    text: 'There should not be duplicated names',
    validate: (str = '') =>
      !IFs.find(_IF => editID !== _IF.id && _IF.name && _IF.name === str)
  },
  {
    text: 'The length should between 3 and 16',
    validate: (str = '') => 3 <= str.length && str.length <= 16
  }
];

export const vlanIDRules = (IFs, editID) => [
  {
    text: 'It should only composed by numbers',
    validate: (str = '') => /^[0-9]+$/.test(str)
  },
  {
    text: 'It should be 1~4096',
    validate: str => 1 <= Number(str) && Number(str) <= 4096
  },
  {
    text: 'There should not be duplicated IDs',
    validate: str =>
      !IFs.find(
        _IF => editID !== _IF.id && _IF.vlanID && _IF.vlanID === Number(str)
      )
  }
];

export const IPAddrRules = (IFs, editID) => [
  {
    text: 'This should follow IPv4 rules',
    validate: (str = '') => ipaddr.IPv4.isValidFourPartDecimal(str)
  },
  {
    text: 'There should not be duplicated IP addresses',
    validate: str =>
      !IFs.find(_IF => editID !== _IF.id && _IF.IPAddr && _IF.IPAddr === str)
  }
];

export const IPMaskRules = [
  {
    text: 'This should be a valid IPv4 mask',
    validate: (str = '') =>
      str in IPMasks || Object.values(IPMasks).includes(str)
  }
];

export const gatewayRules = [
  {
    text: 'The gateway should follow IPv4 rules.',
    validate: (gateway = '') => ipaddr.IPv4.isValidFourPartDecimal(gateway)
  },
  {
    text: 'The gateway should be the subnet of the default network interface.',
    validate: (gateway, IF) => {
      if (isEmpty(IF)) return true;
      const maskBits = ipaddr.IPv4.parse(
        IF.IPMask
      ).prefixLengthFromSubnetMask();
      return ipaddr.IPv4.parse(gateway).match(
        ipaddr.IPv4.parse(IF.IPAddr),
        maskBits
      );
    }
  }
];
