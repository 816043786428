import React from 'react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import {
  TextInput,
  TileGroup,
  RadioTile,
  OrderedList,
  ListItem
} from '@carbon/react';
import PropTypes from 'prop-types';
import { hostnameRules, IPRules } from './utils';
import CreateFormLabel from '@components/CreateFormLabel';
import { isEmpty } from 'lodash';

const HAStep = ({ HA, HASettings, handleHAChange, handleHASettingsChange }) => {
  const isVirtualIPValid = IPRules.every(rule =>
    rule.validate(HASettings?.virtualIP)
  );
  const virtualIPInvalidText =
    IPRules.find(rule => !rule.validate(HASettings?.virtualIP))?.text ||
    'valid';

  const isVirtualHostnameValid = hostnameRules.every(rule =>
    rule.validate(HASettings?.virtualHostname)
  );
  const virtualHostnameInvalidText =
    hostnameRules.find(rule => !rule.validate(HASettings?.virtualHostname))
      ?.text || 'valid';

  const canSubmit = !HA || (isVirtualIPValid && isVirtualHostnameValid);

  return (
    <CreateTearsheetStep
      title="High Availability Configuration"
      hasFieldset={false}
      disableSubmit={!canSubmit}
    >
      <TileGroup
        defaultSelected="default-selected"
        legend="High availability (HA)"
        name="radio tile group"
        valueSelected={HA ? 'enable' : 'disable'}
        onChange={selectedValue => handleHAChange(selectedValue === 'enable')}
      >
        <RadioTile id="radio-disable" value="disable" className="ha-radio-tile">
          <p>Standalone</p>
          <span style={{ color: 'gray' }}>
            There will be a unique control, control-converged, or edge-core.
            <br />
            Besides, moderators are not allowed in standalone.
          </span>
        </RadioTile>
        <RadioTile id="radio-enable" value="enable" className="ha-radio-tile">
          <p>High Availability</p>
          <span style={{ color: 'gray' }}>
            Control virtual hostname and IP are required, and the nodes must
            satisfy one of the following configurations:
            <OrderedList
              style={{
                marginTop: '0.2rem',
                marginLeft: '1.5rem'
              }}
            >
              <ListItem style={{ color: 'gray' }}>
                <strong>Normal configuration</strong>: There are 3 control and
                control-converged nodes without any edge-core or moderator
                nodes.
              </ListItem>
              <ListItem style={{ color: 'gray' }}>
                <strong>Edge configuration</strong>: There are &quot;3
                edge-cores&quot; or &quot;2 edge-cores + 1 moderator&quot;
                without any control or control-converged nodes.
              </ListItem>
            </OrderedList>
          </span>
        </RadioTile>
      </TileGroup>
      {HA && (
        <ul style={{ marginTop: '0.5rem' }}>
          <li className="ha-ul-item">
            <TextInput
              id="control-virtual-hostname-input"
              labelText={
                <CreateFormLabel
                  label="Set control virtual hostname"
                  isRequired={true}
                />
              }
              invalid={
                !isVirtualHostnameValid && !isEmpty(HASettings?.virtualHostname)
              }
              invalidText={virtualHostnameInvalidText}
              value={HASettings?.virtualHostname || ''}
              placeholder="Set control virtual hostname"
              onChange={evt => {
                const text = evt.target.value;
                handleHASettingsChange('virtualHostname', text.toLowerCase());
              }}
            />
          </li>
          <li className="ha-ul-item">
            <TextInput
              id="control-virtual-ip-input"
              labelText={
                <CreateFormLabel
                  label="Set control virtual IP"
                  isRequired={true}
                />
              }
              invalid={!isVirtualIPValid && !isEmpty(HASettings?.virtualIP)}
              invalidText={virtualIPInvalidText}
              value={HASettings?.virtualIP || ''}
              placeholder="Set control virtual IP"
              onChange={evt => {
                const text = evt.target.value;
                handleHASettingsChange('virtualIP', text);
              }}
            />
          </li>
        </ul>
      )}
    </CreateTearsheetStep>
  );
};

HAStep.protoTypes = {
  HA: PropTypes.bool.isRequired,
  HASettings: PropTypes.object.isRequired,
  handleHAChange: PropTypes.func.isRequired,
  handleHASettingsChange: PropTypes.func.isRequired
};

export { HAStep };
