import React, { useState } from 'react';
import {
  Modal,
  FileUploaderItem,
  Button,
  FileUploaderDropContainer
} from '@carbon/react';
import { isEmpty } from 'lodash';
import { checkClusterDetailValid } from './checkClusterDetailValid';
import JSZip from 'jszip';

const ImportModal = ({ isOpened, onClose, handleImportCluster }) => {
  const [uploadFileName, setFileName] = useState('');
  const [clusterDetail, setClusterDetail] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  const handleUploadZip = addedFiles => {
    const file = addedFiles[0]; // we only support upload one file at a time
    const fileName = file?.name || '';
    const zip = new JSZip();
    zip
      .loadAsync(file)
      .then(zip => {
        try {
          const zipFiles = Object.keys(zip.files);
          const jsonFile = zipFiles.find(filename =>
            filename.endsWith('clusterDetail.json')
          );
          if (!jsonFile) {
            throw new Error(
              'Cannot find clusterDetail.json in the uploaded zip file!'
            );
          }
          zip
            .file(jsonFile)
            .async('string')
            .then(data => {
              try {
                const newClusterDetail = JSON.parse(data);
                setFileName(fileName);
                checkClusterDetailValid(newClusterDetail);
                setClusterDetail(newClusterDetail);
                setErrorMessage(null);
              } catch (e) {
                setErrorMessage(e.message);
              }
            });
        } catch (e) {
          setFileName(fileName);
          setErrorMessage(e.message);
        }
      })
      .catch(() => setErrorMessage('This is an invalid zip structure!'));
  };

  const handleImportClick = () => {
    handleImportCluster(clusterDetail);
    onClose(false);
  };

  const isSubmitDisabled = !!errorMessage || isEmpty(clusterDetail);

  return (
    <Modal
      open={isOpened}
      modalHeading="Import cluster configuration"
      passiveModal
      onRequestClose={() => onClose(false)}
    >
      <p style={{ marginBottom: '1rem' }}>
        Please upload the exported
        <strong> [cluster_name].zip </strong>
        and click import to create a new cluster.
      </p>
      <div className="file-upload-wrapper">
        <FileUploaderDropContainer
          data-modal-primary-focus
          style={{ maxWidth: 'fit-content' }}
          accept={['.zip']}
          labelText="Drag and drop cluster zip here or click to upload"
          multiple={false}
          onAddFiles={(_evt, { addedFiles }) => handleUploadZip(addedFiles)}
        />
        {(!isEmpty(clusterDetail) || errorMessage !== null) && (
          <FileUploaderItem
            errorBody={errorMessage}
            errorSubject="Error"
            iconDescription="Delete file"
            invalid={!!errorMessage}
            name={uploadFileName}
            onDelete={() => {
              setClusterDetail({});
              setErrorMessage(null);
            }}
            status={isEmpty(clusterDetail) ? 'edit' : 'complete'} // edit is error icon
            size="md"
          />
        )}
      </div>
      <Button
        disabled={isSubmitDisabled}
        onClick={handleImportClick}
        aria-label="Import Cluster"
        style={{ marginTop: '1rem' }}
        data-test="import-btn"
      >
        Import Cluster
      </Button>
    </Modal>
  );
};

export default ImportModal;
