import React, { useState } from 'react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import { TextInput } from '@carbon/react';
import { Checkmark, Close } from '@carbon/icons-react';
import PropTypes from 'prop-types';
import CreateFormLabel from '@components/CreateFormLabel';

const checkRegex = str =>
  /^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])$/.test(str);

const checkStartWithAlpha = str => /^[a-z]/.test(str);

const checkLength = str => 1 <= str.length && str.length <= 63;

const rules = [
  {
    text: 'New hostname must follow the hostname rule',
    validate: checkRegex
  },
  {
    text: 'New hostname should be comprised of 1~63 characters',
    validate: checkLength
  },
  {
    text: 'The first character must be an alphabet',
    validate: checkStartWithAlpha
  }
];

const regexRules = [
  {
    text: 'New hostname should only compose by "0-9", "a-z" or "-"(hyphen)',
    validate: str => /^[0-9a-z-]*$/.test(str)
  },
  {
    text: 'New hostname should not start with "-"',
    validate: str => !/^-/.test(str)
  },
  {
    text: 'New hostname should not end with "-"',
    validate: str => !/-$/.test(str)
  },
  {
    text: 'New hostname needs to be specified',
    validate: str => str !== ''
  }
];

const HostnameStep = ({ hostname, handleHostnameChange }) => {
  const [isInvalid, setIsInvalid] = useState(hostname === '');
  const [isRegexInvalid, setIsRegexInvalid] = useState(false);
  const [regexInvalidText, setRegexInvalidText] = useState('');

  return (
    <CreateTearsheetStep
      title="Hostname Configuration"
      hasFieldset={false}
      disableSubmit={isInvalid}
    >
      <TextInput
        id="hostname-input"
        labelText={
          <CreateFormLabel label="Set new hostname" isRequired={true} />
        }
        value={hostname}
        invalid={isRegexInvalid}
        invalidText={regexInvalidText}
        placeholder="Enter new hostname..."
        onChange={event => {
          const text = event.target.value.toLowerCase();
          setIsRegexInvalid(!checkRegex(text));
          setRegexInvalidText(
            regexRules.find(rule => !rule.validate(text))?.text ||
              'New hostname must follow the hostname rule'
          );
          handleHostnameChange(text);
          setIsInvalid(!rules.every(rule => rule.validate(text)));
        }}
      />
      <ul className="cube--hostname-requirements">
        <p className="cube--hostname-condition-title">
          Hostname Requirements :
        </p>
        {rules.map((rule, index) => (
          <li key={index}>
            {rule.validate(hostname) ? (
              <Checkmark className="cube--hostname-condition-pass" />
            ) : (
              <Close className="cube--hostname-condition-error" />
            )}
            {rule.text}
          </li>
        ))}
      </ul>
    </CreateTearsheetStep>
  );
};

HostnameStep.propTypes = {
  hostname: PropTypes.string.isRequired,
  handleHostnameChange: PropTypes.func.isRequired
};

export default HostnameStep;
