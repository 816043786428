import axios from 'axios';
const SNAPSHOT_API = '/api/snapshot';

const getClusterDetail = (id = '') =>
  axios.get(SNAPSHOT_API + '/getClusterDetail', {
    params: { id },
    headers: { 'Content-Type': 'application/json' }
  });

const updateClusterSnapshot = (payload = {}) =>
  axios
    .post(SNAPSHOT_API, payload, {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });

const getClusterSnapshot = fileName =>
  axios.get(SNAPSHOT_API + '/getSnapshot', {
    params: { id: fileName },
    responseType: 'blob' // download binary file as blob
  });

export { getClusterDetail, getClusterSnapshot, updateClusterSnapshot };
