import React from 'react';
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderMenuButton,
  SkipToContent,
  SideNav,
  SideNavItems,
  SideNavLink,
  SideNavDivider
} from '@carbon/react';
import { Link } from 'react-router-dom';
import { Add, EdgeCluster, TrashCan, Home } from '@carbon/icons-react';
import cube from '@src/image/cube.png';

const UIHeader = ({
  currentId,
  clustersInfo,
  setCurrentId,
  setIsCreateCluster,
  handleDeleteAllClusters
}) => {
  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Header aria-label="Cube Snapshot Generator">
          <SkipToContent />
          <HeaderMenuButton
            aria-label="Open menu"
            isCollapsible
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />
          <SideNav
            aria-label="Side navigation"
            isRail
            expanded={isSideNavExpanded}
            onOverlayClick={onClickSideNavExpand}
          >
            <SideNavItems>
              <SideNavLink
                renderIcon={Home}
                onClick={() => setCurrentId(undefined)}
                large
              >
                Home
              </SideNavLink>
              <SideNavDivider />
              {clustersInfo.map(info => (
                <SideNavLink
                  key={info.id}
                  large
                  onClick={() => setCurrentId(info.id)}
                  renderIcon={EdgeCluster}
                  isActive={info.id === currentId}
                >
                  <span className="side-nav-content">
                    {info?.name || 'unknown'}
                  </span>
                </SideNavLink>
              ))}

              <SideNavLink
                renderIcon={Add}
                onClick={() => setIsCreateCluster(true)}
                large
              >
                Create Cluster
              </SideNavLink>

              {clustersInfo.length > 0 && (
                <>
                  <SideNavDivider />
                  <SideNavLink
                    renderIcon={TrashCan}
                    onClick={() => {
                      handleDeleteAllClusters();
                    }}
                    large
                  >
                    Delete all clusters
                  </SideNavLink>
                </>
              )}
            </SideNavItems>
          </SideNav>
          <div className="cube--header-logo__wrapper">
            <Link to="/" onClick={() => setCurrentId(undefined)}>
              <img
                src={cube}
                alt="logo"
                width="30px"
                style={{ marginLeft: '1rem' }}
              />
            </Link>
            <span className="cube-header-logo__divider" />
            <HeaderName
              as={Link}
              to="/"
              prefix=""
              style={{ paddingLeft: '0.5rem' }}
              onClick={() => setCurrentId(undefined)}
            >
              {process.env.APP_NAME || 'Cube Snapshot Generator'}
            </HeaderName>
          </div>
        </Header>
      )}
    />
  );
};

export default UIHeader;
