import React, { useState } from 'react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import { ProgressIndicator, ProgressStep, Dropdown } from '@carbon/react';
import PropTypes from 'prop-types';
import TermsComponent from './TermsComponent';
import {
  bigstack_terms_en,
  bigstack_terms_jp,
  bigstack_terms_zh,
  mit_terms_en,
  mit_terms_jp,
  mit_terms_zh
} from './TermsFiles';

const bigstackTermsText = {
  English: bigstack_terms_en,
  Japanese: bigstack_terms_jp,
  Chinese: bigstack_terms_zh
};

const nonBigstackTermsText = {
  English: mit_terms_en,
  Japanese: mit_terms_jp,
  Chinese: mit_terms_zh
};

const termsLanguages = ['English', 'Chinese', 'Japanese'];

const LicenseStep = ({ isEditWizard }) => {
  const [isLicenseAgree, setIsLicenseAgree] = useState(isEditWizard);
  const [termsLanguage, setTermsLanguage] = useState(termsLanguages[0]);
  const [LicenseIndex, setLicenseIndex] = useState(isEditWizard ? 1 : 0);

  const handleCheckBoxChange = checked => setIsLicenseAgree(checked);

  return (
    <CreateTearsheetStep
      title="License Agreements"
      hasFieldset={false}
      disableSubmit={!isLicenseAgree}
    >
      <ProgressIndicator
        onChange={index => {
          setLicenseIndex(index);
        }}
        currentIndex={LicenseIndex}
        spaceEqually
      >
        <ProgressStep label="Bigstack Terms" />
        <ProgressStep label="Open Source Terms" />
      </ProgressIndicator>
      <Dropdown
        id="language-dropdown"
        label=""
        items={termsLanguages}
        initialSelectedItem={termsLanguage}
        onChange={({ selectedItem }) => {
          setTermsLanguage(selectedItem);
        }}
      />
      {LicenseIndex === 0 && (
        <TermsComponent
          index={0}
          termText={bigstackTermsText[`${termsLanguage}`]}
          onBtnClick={() => {
            setLicenseIndex(1);
            setIsLicenseAgree(true);
          }}
        />
      )}
      {LicenseIndex === 1 && (
        <TermsComponent
          index={1}
          isAgree={isLicenseAgree}
          onCheck={handleCheckBoxChange}
          termText={nonBigstackTermsText[`${termsLanguage}`]}
          onBtnClick={() => setLicenseIndex(0)}
        />
      )}
    </CreateTearsheetStep>
  );
};

LicenseStep.propTypes = {
  isEditWizard: PropTypes.bool.isRequired
};

export default LicenseStep;
