import React, { useEffect, useState, useMemo } from 'react';
import { CreateTearsheet } from '@carbon/ibm-products';
import { cloneDeep, isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  HostnameStep,
  NetworkStep,
  NodeRoleStep,
  createDefaultRoleSettings
} from './components';

const blockClass = `cube-create-config-wizard`;

const isIFSelectable = (IF, option) =>
  IF?.enabled &&
  !(IF.type === 'init' && IF.master) &&
  !((option === 'mgmtIF' || option === 'defaultIF') && isEmpty(IF.IPAddr));

const NodeWizard = ({ selectedData, onClose, isOpened, onSubmit }) => {
  const [hostname, setHostname] = useState('');
  const [initIFs, setInitIFs] = useState([
    {
      id: uuidv4(),
      type: 'init',
      name: 'IF.1',
      enabled: false
    }
  ]);
  const [bondIFs, setBondIFs] = useState([]);
  const [vlanIFs, setVlanIFs] = useState([]);
  const IFs = useMemo(
    () => [...initIFs, ...bondIFs, ...vlanIFs],
    [initIFs, bondIFs, vlanIFs]
  );

  const [defaultIF, setDefaultIF] = useState({});
  const [defaultGateway, setDefaultGateway] = useState('192.168.0.254');
  const [role, setRole] = useState('control');
  const [roleSettings, setRoleSettings] = useState(
    createDefaultRoleSettings(role)
  );

  const [roleStepId, setRoleStepId] = useState(uuidv4());

  const isEditWizard = !isEmpty(selectedData);

  useEffect(() => {
    const newIFid = uuidv4();
    setHostname(selectedData?.hostname || '');
    setInitIFs(
      selectedData?.initIFs || [
        {
          id: newIFid,
          type: 'init',
          name: 'IF.1',
          enabled: false
        }
      ]
    );
    setBondIFs(selectedData?.bondIFs || []);
    setVlanIFs(selectedData?.vlanIFs || []);
    setDefaultIF(selectedData?.defaultIF || {});
    setDefaultGateway(selectedData?.defaultGateway || '192.168.0.254');

    setRole(selectedData?.role || 'control');
    const newRoleSettings = createDefaultRoleSettings(
      selectedData?.role || 'control'
    );
    setRoleSettings(selectedData?.roleSettings || newRoleSettings);
  }, [selectedData, isOpened]);

  useEffect(() => {
    // update role settings if the IF becomes invalid
    let shouldUpdate = false;

    const newRoleSettings = Object.keys(roleSettings).reduce((dict, option) => {
      if (isEmpty(roleSettings[option])) return { ...dict, [option]: {} };
      const IF = IFs.find(_IF => _IF.id === roleSettings[option]?.id);
      if (!isIFSelectable(IF, option)) {
        shouldUpdate = true;
        return { ...dict, [option]: {} };
      }
      return { ...dict, [option]: roleSettings[option] };
    }, {});

    if (shouldUpdate) {
      setRoleSettings(newRoleSettings);
      setRoleStepId(uuidv4());
    }
  }, [roleSettings, IFs]);

  useEffect(() => {
    // update default IF if the IF becomes invalid
    if (isEmpty(defaultIF)) return;
    const IF = IFs.find(_IF => _IF.id === defaultIF?.id);
    if (!isIFSelectable(IF, 'defaultIF')) setDefaultIF({});
  }, [defaultIF, IFs]);

  const newId = uuidv4();

  const IFidtoName = IFid => IFs.find(_IF => _IF.id === IFid)?.name || 'None';

  const handleHostnameChange = newHostname => {
    setHostname(newHostname);
  };

  const handleInitIFResize = num => {
    if (num < 1 || num > 100) return;
    if (initIFs.length < num) {
      let newInitIFs = cloneDeep(initIFs);
      while (newInitIFs.length < num) {
        newInitIFs.push({
          id: uuidv4(),
          type: 'init',
          name: `IF.${newInitIFs.length + 1}`,
          enabled: false
        });
      }
      setInitIFs(newInitIFs);
      return [];
    } else {
      return handleRemoveIFs(
        initIFs.filter((IF, index) => index >= num).map(IF => IF.id)
      );
    }
  };

  const handleCreateBond = (IFids, newID) => {
    const newInitIFs = initIFs.map(IF =>
      IFids.includes(IF.id)
        ? {
            ...IF,
            master: newID,
            IPAddr: undefined,
            IPMask: undefined,
            enabled: true
          }
        : IF
    );
    setInitIFs(newInitIFs);

    let newBondName =
      ['mgmt', 'data', 'ext', 'stor', 'backend'].find(name =>
        bondIFs.every(IF => IF.name !== name)
      ) || 'newbdname';

    setBondIFs([
      ...bondIFs,
      {
        id: newID,
        type: 'bond',
        name: newBondName,
        slaves: IFids,
        enabled: true
      }
    ]);
    setVlanIFs(vlanIFs.filter(IF => !IFids.includes(IF.master)));
  };

  const handleRemoveIFs = IFids => {
    IFids = new Set(IFids);

    bondIFs.map(IF => {
      if (IF.slaves.every(_IFid => IFids.has(_IFid))) IFids.add(IF.id);
    });

    vlanIFs.map(IF => {
      if (IFids.has(IF.master)) IFids.add(IF.id);
    });

    setInitIFs(
      initIFs
        .filter(IF => !IFids.has(IF.id))
        .map(IF => ({
          ...IF,
          master: IF.master && !IFids.has(IF.master) ? IF.master : undefined
        }))
    );

    setBondIFs(
      bondIFs
        .filter(IF => !IFids.has(IF.id))
        .map(IF => ({
          ...IF,
          slaves: IF.slaves.filter(_IFid => !IFids.has(_IFid))
        }))
    );

    setVlanIFs(
      vlanIFs.filter(IF => !IFids.has(IF.id) && !IFids.has(IF.master))
    );

    if (!isEmpty(defaultIF) && defaultIF.id in IFids) setDefaultIF({});
    return [...IFids];
  };

  const handleCreateVLAN = (IF, newID) => {
    let newVlanID = 1;
    while (vlanIFs.find(IF => IF.vlanID === newVlanID)) newVlanID++;

    setVlanIFs([
      ...vlanIFs,
      {
        id: newID,
        type: 'vlan',
        name: `${IF.name}.${newVlanID}`,
        master: IF.id,
        vlanID: newVlanID,
        enabled: true
      }
    ]);
  };

  const handleIFChange = (IF, dict) => {
    if (IF.type === 'vlan' && 'vlanID' in dict)
      // update the name of vlan when changing vlanID
      dict.name = `${IFidtoName(IF.master)}.${dict.vlanID}`;
    const actions = {
      init: [initIFs, setInitIFs],
      bond: [bondIFs, setBondIFs],
      vlan: [vlanIFs, setVlanIFs]
    };
    const [getter, setter] = actions[IF.type];
    setter(getter.map(_IF => (_IF.id === IF.id ? { ..._IF, ...dict } : _IF)));

    if (IF.type === 'bond' && 'name' in dict) {
      // update the name of vlan when changing master's name
      setVlanIFs(
        vlanIFs.map(vlanIF =>
          vlanIF.master === IF.id
            ? { ...vlanIF, name: `${dict.name}.${vlanIF.vlanID}` }
            : vlanIF
        )
      );
    }
  };

  const handleDefaultIFChange = IF => {
    setDefaultIF({ id: IF.id, type: IF.type, name: IF.name });
  };

  const handleDefaultGatewayChange = ip => {
    setDefaultGateway(ip);
  };

  const handleRoleChange = newRole => {
    const newRoleSettings = createDefaultRoleSettings(newRole, roleSettings);
    // const newHASettings = createDefaultHASettings(HA, newRole, HASettings);
    setRole(newRole);
    setRoleSettings(newRoleSettings);
    // setHASettings(newHASettings);
  };

  const handleRoleSettingsChange = (option, value) => {
    setRoleSettings({ ...roleSettings, [option]: value });
  };

  return (
    <div>
      <style>{`.${blockClass} { opacity: 0 }`};</style>
      <CreateTearsheet
        id={`${blockClass}-node`}
        className={blockClass}
        submitButtonText="Save"
        cancelButtonText="Cancel"
        backButtonText="Back"
        nextButtonText="Next"
        title="Node Snapshot Configuration"
        open={isOpened}
        onClose={() => onClose(false)}
        onRequestSubmit={() => {
          onSubmit(prevConfig => {
            const oldConfig = cloneDeep(prevConfig);
            const newConfig = {
              hostname,
              initIFs,
              bondIFs,
              vlanIFs,
              defaultIF,
              defaultGateway,
              role,
              roleSettings
            };
            if (isEditWizard)
              return oldConfig.map(config =>
                config.id === selectedData.id
                  ? {
                      ...config,
                      ...newConfig
                    }
                  : config
              );
            return [
              ...oldConfig,
              {
                id: newId,
                ...newConfig
              }
            ];
          });
        }}
      >
        <HostnameStep
          hostname={hostname}
          handleHostnameChange={handleHostnameChange}
        />
        <NetworkStep
          initIFs={initIFs}
          bondIFs={bondIFs}
          vlanIFs={vlanIFs}
          defaultIF={defaultIF}
          defaultGateway={defaultGateway}
          handleInitIFResize={handleInitIFResize}
          handleCreateBond={handleCreateBond}
          handleRemoveIFs={handleRemoveIFs}
          handleCreateVLAN={handleCreateVLAN}
          handleIFChange={handleIFChange}
          handleDefaultIFChange={handleDefaultIFChange}
          handleDefaultGatewayChange={handleDefaultGatewayChange}
        />
        <NodeRoleStep
          key={roleStepId}
          IFs={IFs}
          role={role}
          roleSettings={roleSettings}
          handleRoleChange={handleRoleChange}
          handleRoleSettingsChange={handleRoleSettingsChange}
        />
      </CreateTearsheet>
    </div>
  );
};

export default NodeWizard;
