import React from 'react';
import { InlineNotification, OrderedList, ListItem } from '@carbon/react';
import {
  findDuplicatedHostname,
  findDuplicatedIF,
  findInvalidVirtualHostname,
  findInvalidVirtualIP,
  isRoleDistributionValid,
  hasComputeRole,
  hasModeratorRole,
  commonDownloadNotificationProps as commonNotificationProps
} from '@components/utils';

export const DownloadNotification = ({ clusterConfig, tableData }) => {
  const duplicatedHostname = findDuplicatedHostname(tableData);
  const duplicatedIF = findDuplicatedIF(tableData);
  const invalidVirtualHostname = findInvalidVirtualHostname(
    clusterConfig,
    tableData
  );
  const invalidVirtualIP = findInvalidVirtualIP(clusterConfig, tableData);
  const isRoleValid = isRoleDistributionValid(clusterConfig, tableData);
  const hasCompute = hasComputeRole(tableData);
  const hasModerator = hasModeratorRole(tableData);

  let successMessages = [],
    failMessages = [];

  if (duplicatedHostname) {
    failMessages.push(
      <InlineNotification
        {...commonNotificationProps}
        key="hostname error"
        statusIconDescription="hostname error"
        title="Hostname Error"
        subtitle={`Hostnames should not be duplicated, but ${duplicatedHostname} is used more than once.`}
        kind="error"
      />
    );
  } else {
    successMessages.push(
      <InlineNotification
        {...commonNotificationProps}
        key="hostname"
        statusIconDescription="hostname"
        title="Hostname"
        subtitle="Each node has its unique hostname."
        kind="success"
      />
    );
  }

  if (duplicatedIF) {
    failMessages.push(
      <InlineNotification
        {...commonNotificationProps}
        key="network interface error"
        statusIconDescription="network interface error"
        subtitle={`${duplicatedIF.config1.IFName} of ${duplicatedIF.config1.hostname} and ${duplicatedIF.config2.IFName} of ${duplicatedIF.config2.hostname} share the same IP address ${duplicatedIF.IPAddr}`}
        title="Network Interface Error"
        kind="error"
      />
    );
  } else {
    successMessages.push(
      <InlineNotification
        {...commonNotificationProps}
        key="network interface"
        statusIconDescription="network interface"
        subtitle="Each network interface is configured with a unique IP."
        title="Network Interface"
        kind="success"
      />
    );
  }

  if (hasCompute) {
    successMessages.push(
      <InlineNotification
        {...commonNotificationProps}
        key="compute role"
        statusIconDescription="compute role"
        title="Compute Role"
        subtitle="There is a compute, control-converged, or edge-core node."
        kind="success"
      />
    );
  } else {
    failMessages.push(
      <InlineNotification
        {...commonNotificationProps}
        key="compute role error"
        statusIconDescription="compute role error"
        title="Compute Role Error"
        subtitle="Each cluster should have at least one compute, control-converged, or edge-core node."
        kind="error"
      />
    );
  }

  if (clusterConfig.HA) {
    // high availability
    if (invalidVirtualHostname) {
      failMessages.push(
        <InlineNotification
          {...commonNotificationProps}
          key="control virtual hostname error"
          statusIconDescription="control virtual hostname error"
          title="Control virtual hostname Error"
          subtitle={`Hostnames should not be duplicated, but ${invalidVirtualHostname} is used by the control virtual hostname and other nodes.`}
          kind="error"
        />
      );
    } else {
      successMessages.push(
        <InlineNotification
          {...commonNotificationProps}
          key="control virtual hostname"
          statusIconDescription="control virtual hostname"
          title="Control Virtual Hostname"
          subtitle="The control virtual hostname does not share the same hostname with any nodes in the cluster."
          kind="success"
        />
      );
    }

    if (invalidVirtualIP) {
      failMessages.push(
        <InlineNotification
          {...commonNotificationProps}
          key="control virtual IP error"
          statusIconDescription="control virtual IP error"
          title="Control Virtual IP Error"
          subtitle={`The control virtual IP ${invalidVirtualIP.IPAddr} is used by ${invalidVirtualIP.IFName} of ${invalidVirtualIP.hostname}`}
          kind="error"
        />
      );
    } else {
      successMessages.push(
        <InlineNotification
          {...commonNotificationProps}
          key="control virtual IP"
          statusIconDescription="control virtual IP"
          title="Control Virtual IP"
          subtitle="The control virtual IP does not share the same IP with any other network interfaces."
          kind="success"
        />
      );
    }

    if (isRoleValid) {
      successMessages.push(
        <InlineNotification
          {...commonNotificationProps}
          key="high availability"
          statusIconDescription="high availability"
          title="High Availability"
          subtitle="The cluster follows high availability role configurations."
          kind="success"
        />
      );
    } else {
      failMessages.push(
        <InlineNotification
          {...commonNotificationProps}
          key="high availability error"
          statusIconDescription="high availability error"
          title="High Availability Error"
          subtitle="In high availability, the nodes must satisfy one of the following configurations:"
          kind="error"
        >
          <OrderedList
            style={{
              marginTop: '0.5rem',
              marginBottom: '1rem',
              marginLeft: '1.5rem'
            }}
          >
            <ListItem>
              <strong>Normal configuration</strong>: There are 3 control and
              control-converged nodes without any edge-core or moderator nodes.
            </ListItem>
            <ListItem>
              <strong>Edge configuration</strong>: There are &quot;3
              edge-cores&quot; or &quot;2 edge-cores + 1 moderator&quot; without
              any control or control-converged nodes.
            </ListItem>
          </OrderedList>
        </InlineNotification>
      );
    }
  }

  if (!clusterConfig.HA) {
    // standalone
    if (isRoleValid) {
      successMessages.push(
        <InlineNotification
          {...commonNotificationProps}
          key="standalone"
          statusIconDescription="standalone"
          title="Standalone"
          subtitle="There is a unique control, control-converged, edge-core or moderator."
          kind="success"
        />
      );
    } else {
      failMessages.push(
        <InlineNotification
          {...commonNotificationProps}
          key="standalone error"
          statusIconDescription="standalone error"
          title="Standalone Error"
          subtitle="In standalone, there is a unique control, control-converged, or edge-core."
          kind="error"
        />
      );
    }

    if (hasModerator) {
      failMessages.push(
        <InlineNotification
          {...commonNotificationProps}
          key="moderator error"
          statusIconDescription="moderator error"
          title="Moderator Error"
          subtitle="In standalone, moderators are not allowed. If you need a moderator, please switch to high availability."
          kind="error"
        />
      );
    } else {
      successMessages.push(
        <InlineNotification
          {...commonNotificationProps}
          key="moderator"
          statusIconDescription="moderator"
          title="Moderator"
          subtitle="In standalone, there is no moderator."
          kind="success"
        />
      );
    }
  }

  return (
    <div className="download-notification-div">
      {successMessages}
      {failMessages}
    </div>
  );
};

export const isDanger = (clusterConfig, tableData) => {
  const duplicatedHostname = findDuplicatedHostname(tableData);
  const duplicatedIF = findDuplicatedIF(tableData);
  const invalidVirtualHostname = findInvalidVirtualHostname(
    clusterConfig,
    tableData
  );
  const invalidVirtualIP = findInvalidVirtualIP(clusterConfig, tableData);
  const isRoleInvalid = !isRoleDistributionValid(clusterConfig, tableData);
  const hasCompute = hasComputeRole(tableData);
  const hasModerator = hasModeratorRole(tableData);

  return (
    !!duplicatedHostname ||
    !!duplicatedIF ||
    !hasCompute ||
    !!invalidVirtualHostname ||
    !!invalidVirtualIP ||
    isRoleInvalid ||
    (!clusterConfig.HA && hasModerator)
  );
};
