import React from 'react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import { Dropdown } from '@carbon/react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { roleOptions, prompts, rules } from './utils';
import CreateFormLabel from '@components/CreateFormLabel';

const NodeRoleStep = ({
  IFs,
  role,
  roleSettings,
  handleRoleChange,
  handleRoleSettingsChange
}) => {
  const selectableIFs =
    IFs.filter(IF => IF.enabled && !(IF.type === 'init' && IF.master)) || [];

  const optionsWithDropDown = roleOptions[role];

  const IFidtoName = IFid => IFs.find(_IF => _IF.id === IFid)?.name || 'None';

  const canSubmit = roleOptions[role].every(option =>
    rules[option].every(rule =>
      rule.validate(roleSettings[option], selectableIFs)
    )
  );

  return (
    <CreateTearsheetStep
      title="Cube Role Configuration"
      hasFieldset={false}
      disableSubmit={!canSubmit}
    >
      <Dropdown
        className="role-ul-item"
        id="node-role-dropdown"
        items={Object.keys(roleOptions)}
        selectedItem={role}
        label="Set cube role"
        titleText="Select this node role"
        onChange={({ selectedItem }) => handleRoleChange(selectedItem)}
      />
      <ul>
        {optionsWithDropDown.map(option => {
          let items = selectableIFs;
          if (option === 'storIFBackend') {
            // storage backend interface is optional
            items = [{}].concat(selectableIFs);
          } else if (option === 'mgmtIF') {
            // the IP of management interface must be specified
            items = selectableIFs.filter(IF => IF.IPAddr !== undefined);
          }

          const isValid = rules[option].every(rule =>
            rule.validate(roleSettings[option], selectableIFs)
          );

          const selectedIF = IFs.find(
            _IF => _IF.id === roleSettings[option]?.id
          );

          const isWarn =
            !isEmpty(selectedIF) && selectedIF.IPAddr === undefined;

          const invalidText =
            rules[option].find(
              rule => !rule.validate(roleSettings[option], selectableIFs)
            )?.text || '';
          return (
            <li className="role-ul-item" key={option}>
              <Dropdown
                id={`${option}-dropdown`}
                titleText={<CreateFormLabel {...prompts[option]} />}
                label="Select a network interface"
                items={items}
                onChange={({ selectedItem }) => {
                  let newValue = selectedItem;
                  if (!isEmpty(newValue)) {
                    newValue = {
                      id: selectedItem.id,
                      type: selectedItem.type
                    };
                  }
                  handleRoleSettingsChange(option, newValue);
                }}
                initialSelectedItem={
                  isEmpty(roleSettings[option]) ? null : roleSettings[option]
                }
                invalid={!isValid && !isEmpty(roleSettings[option])}
                invalidText={invalidText}
                warn={isWarn}
                warnText="The IP of the interface has not been configured"
                itemToString={item => IFidtoName(item.id)}
              />
            </li>
          );
        })}
      </ul>
    </CreateTearsheetStep>
  );
};

NodeRoleStep.protoTypes = {
  IFs: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
  roleSettings: PropTypes.object.isRequired,
  handleRoleChange: PropTypes.func.isRequired,
  handleRoleSettingsChange: PropTypes.func.isRequired
};

export { NodeRoleStep };
