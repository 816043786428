export const invalidRules = [
  {
    text: 'The cluster name should not be blank',
    validate: (str = '') => str.length > 0
  },
  {
    text: 'The cluster name should not start with dots',
    validate: str => !/^\./.test(str)
  },
  {
    text: 'The cluster name should not contain ?, *, ", /, or \\',
    validate: str => !/[?*"/\\]/.test(str)
  },
  {
    text: 'The cluster name should not be longer than 20 characters',
    validate: str => str.length <= 20
  },
  {
    text: 'The cluster name cannot be composed completely by spaces',
    validate: str => !/^\s*$/.test(str)
  }
];
