import React from 'react';
import { Tooltip, FormLabel } from '@carbon/react';
import { Information } from '@carbon/icons-react';
import PropTypes from 'prop-types';

/**
 * Create customized form label
 * @param {string} label the text on the main label
 * @param {bool} isRequired specify whether the field is required, a red star will be added if it is required
 * @param {string} infoLabel the text on the information tooltip
 * @returns {React.Component} the created React Component
 */
const CreateFormLabel = ({ label, isRequired, infoLabel }) => (
  <>
    <FormLabel>
      {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
    </FormLabel>
    {infoLabel && (
      <Tooltip label={infoLabel} align="right">
        <button className="cds--tooltip__trigger" type="button">
          <Information size={12.5} />
        </button>
      </Tooltip>
    )}
  </>
);

CreateFormLabel.defaultProps = {
  label: '',
  isRequired: false,
  infoLabel: ''
};

CreateFormLabel.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  infoLabel: PropTypes.string
};

export default CreateFormLabel;
