import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Loading,
  Breadcrumb,
  BreadcrumbItem,
  InlineLoading
} from '@carbon/react';
import ClusterDetailCard from '@components/ClusterDetailCard/ClusterDetailCard';
import ConfigTable from '@components/ConfigTable';
import ClusterNotification from '@components/ClusterNotification';
import NodeWizard from '@components/NodeWizard';
import ClusterWizard from '@components/ClusterWizard';
import DownloadModal from '@components/DownloadModal';
import GetSnapshotModal from '@components/GetSnapshotModal';
import { v4 as uuidv4 } from 'uuid';
import { Edit, TrashCan, Download, CloudUpload } from '@carbon/icons-react';
import { isEqual } from 'lodash';
import { updateClusterSnapshot, getClusterDetail } from '@src/api';
import { isDanger } from '@components/DownloadModal/DownloadNotification';

const status = {
  inactive: 'inactive',
  active: 'active',
  finished: 'finished',
  error: 'error'
};

const saveDescription = {
  [status.inactive]: 'Save',
  [status.active]: 'Saving...',
  [status.finished]: 'Saved, click to get cluster snapshot',
  [status.error]: 'Failed to save the cluster'
};

const ClusterPage = ({
  clusterInfo,
  isCreateCluster,
  setCurrentId,
  setIsCreateCluster,
  handleCreateCluster,
  handleEditInfo,
  handleDeleteCluster
}) => {
  const [clusterWizardOpen, setClusterWizardOpen] = useState(false);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [getSnapshotModalOpen, setGetSnapshotModalOpen] = useState(false);
  const [saveStatus, setSaveStatus] = useState(status.inactive);
  const [wizardOpen, setWizardOpen] = useState(false);
  const [wizardId, setWizardId] = useState(uuidv4());
  const [notificationId, setNotificationId] = useState(uuidv4());
  const [tableData, setTableData] = useState([]); // node data
  const [clusterConfig, setClusterConfig] = useState({}); // cluster data
  const [selectedRowId, setSelectedRowId] = useState(null);
  const currentId = useMemo(() => clusterInfo?.id || undefined, [clusterInfo]);
  const clusterDetail = useMemo(
    () => ({
      clusterInfo,
      clusterConfig,
      nodeData: tableData
    }),
    [clusterInfo, clusterConfig, tableData]
  );

  useEffect(() => {
    // fetch data from local storage
    if (!currentId) return;
    const newTableData =
      JSON.parse(localStorage.getItem(`${currentId}-nodes`)) || [];
    const newClusterConfig =
      JSON.parse(localStorage.getItem(`${currentId}-cluster`)) || {};

    setTableData(newTableData);
    setClusterConfig(newClusterConfig);
  }, [currentId]);

  useEffect(() => {
    // update table data in local storage
    if (!currentId) return;
    localStorage.setItem(`${currentId}-nodes`, JSON.stringify(tableData));
    setNotificationId(uuidv4());
  }, [tableData, currentId]);

  useEffect(() => {
    // update cluster config in local storage
    if (!currentId) return;
    localStorage.setItem(`${currentId}-cluster`, JSON.stringify(clusterConfig));
  }, [clusterConfig, currentId]);

  useEffect(() => {
    // fetch clusterDetail from server
    async function retrieveClusterDetail() {
      try {
        const clusterShortId = currentId.slice(-12);
        const { data } = await getClusterDetail(clusterShortId);
        const isSaved = isEqual(clusterDetail, data);
        setSaveStatus(isSaved ? status.finished : status.inactive);
      } catch (e) {
        console.log(`Failed to fetch clusterDetail from server.`);
      }
    }
    retrieveClusterDetail();
  }, [currentId, clusterDetail]);

  const canSave = !isDanger(clusterConfig, tableData);

  if (!clusterConfig) {
    return <Loading />;
  }

  const saveBtnIcon =
    saveStatus === status.inactive
      ? CloudUpload
      : () => (
          <InlineLoading
            status={saveStatus}
            description={saveDescription[saveStatus]}
          />
        );

  const handleSaveClick = async () => {
    if (saveStatus === status.inactive || saveStatus === status.error) {
      setSaveStatus(status.active);
      const res = await updateClusterSnapshot({
        clusterInfo,
        clusterConfig,
        nodeData: tableData
      });

      if (res.status !== 200) {
        setSaveStatus(status.error);
        return;
      }
      setSaveStatus(status.finished);
    }

    setSelectedRowId(null);
    setGetSnapshotModalOpen(true);
  };

  return (
    <>
      <div className="cluster-page">
        <div className="cluster-breadcrumb">
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem
              className="clickable"
              onClick={() => setCurrentId(undefined)}
            >
              Home
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <> {clusterInfo?.name || currentId} </>
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="cluster-page__r1">
          <h2 className="cluster-page__r1-title">
            {clusterInfo?.name || currentId} Configuration
          </h2>
          <div>
            <Button
              kind="tertiary"
              style={{ marginRight: '1rem' }}
              renderIcon={Edit}
              onClick={() => setClusterWizardOpen(true)}
            >
              Edit Cluster
            </Button>
            <Button
              renderIcon={TrashCan}
              kind="danger--tertiary"
              onClick={() => handleDeleteCluster(currentId)}
            >
              Delete Cluster
            </Button>
          </div>
        </div>
        <div className="cluster-page__r2">
          <ClusterDetailCard clusterDetail={clusterDetail} />
        </div>
        <div className="cluster-page__r3">
          <ConfigTable
            rowData={tableData}
            isSaved={saveStatus === status.finished}
            setWizardOpen={setWizardOpen}
            setGetSnapshotModalOpen={setGetSnapshotModalOpen}
            setTableData={setTableData}
            setSelectedRowId={setSelectedRowId}
            setWizardId={setWizardId}
          />
        </div>
        <div className="cluster-page__r4">
          <Button
            disabled={!canSave || saveStatus === status.active}
            renderIcon={saveBtnIcon}
            iconDescription="save"
            kind="secondary"
            className="cluster-page_save_btn"
            onClick={handleSaveClick}
          >
            {saveStatus === status.inactive && 'Save'}
          </Button>
          <Button
            disabled={tableData.length === 0}
            renderIcon={Download}
            kind="primary"
            className="cluster-page_download_btn"
            onClick={() => setDownloadModalOpen(true)}
            style={{ marginLeft: '1rem' }}
          >
            Download
          </Button>
        </div>
      </div>
      <ClusterWizard
        key={`${wizardId}-cluster-${isCreateCluster}`}
        selectedData={!isCreateCluster && clusterConfig}
        isOpened={isCreateCluster || clusterWizardOpen}
        onClose={() => {
          setClusterWizardOpen(false);
          if (isCreateCluster) setIsCreateCluster(false);
        }}
        onSubmit={
          isCreateCluster
            ? handleCreateCluster
            : ({ clusterConfig, clusterInfo }) => {
                setClusterConfig(clusterConfig);
                handleEditInfo(clusterInfo);
              }
        }
        clusterInfo={!isCreateCluster && clusterInfo}
      />
      <NodeWizard
        key={wizardId}
        selectedData={tableData.find(row => row.id === selectedRowId)}
        isOpened={wizardOpen}
        onClose={setWizardOpen}
        onSubmit={setTableData}
      />
      <DownloadModal
        key={`${wizardId}-download`}
        isOpened={downloadModalOpen}
        onClose={setDownloadModalOpen}
        setSaveStatus={setSaveStatus}
        clusterConfig={clusterConfig}
        nodeData={tableData}
        clusterInfo={clusterInfo}
      />
      <GetSnapshotModal
        key={`${wizardId}-share`}
        isOpened={getSnapshotModalOpen}
        nodeName={tableData.find(row => row.id === selectedRowId)?.hostname}
        clusterInfo={clusterInfo}
        onClose={setGetSnapshotModalOpen}
      />
      <ClusterNotification
        key={notificationId}
        clusterConfig={clusterConfig}
        tableData={tableData}
      />
    </>
  );
};

export default ClusterPage;
