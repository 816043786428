import React, { useState } from 'react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import { TextInput, NumberInput } from '@carbon/react';
import PropTypes from 'prop-types';
import CreateFormLabel from '@components/CreateFormLabel';
import { isEmpty } from 'lodash';
import ipaddr from 'ipaddr.js';

const DNSStep = ({ DNS, handleDNSChange, handleDNSResize }) => {
  const rules = [
    {
      text: 'This field should not be blank',
      validate: str => str !== ''
    },
    {
      text: 'DNS IP address should follow the IPv4 rule',
      validate: str => ipaddr.IPv4.isValidFourPartDecimal(str)
    },
    {
      text: 'There should not be duplicated IP addresses',
      validate: str => DNS.filter(dns => dns === str).length === 1
    }
  ];
  const validDetail = DNS.map(dns => ({
    result: rules.every(rule => rule.validate(dns)),
    text:
      rules.find(rule => !rule.validate(dns))?.text ||
      'This must follow the IP rule'
  }));
  const [isNumValid, setIsNumValid] = useState(true);
  const isValid = validDetail.every(detail => detail.result);

  return (
    <CreateTearsheetStep
      title="DNS Configuration"
      subtitle="You can specify maximum 2 different DNS servers."
      hasFieldset={false}
      disableSubmit={!isValid || !isNumValid}
    >
      <ul>
        <li className="dns-ul-item">
          <NumberInput
            id="dns-num-input"
            min={1}
            max={2}
            value={DNS.length}
            label="Number of DNS servers"
            invalidText="It should be between 1 and 2"
            onChange={(event, { value }) => {
              const num = value;
              if (1 <= num && num <= 2) handleDNSResize(num);
              setIsNumValid(1 <= num && num <= 2);
            }}
            disableWheel
          />
        </li>

        {DNS.map((dns, index) => (
          <li key={index} className="dns-ul-item">
            <TextInput
              id={`dns-input-${index + 1}`}
              labelText={
                <CreateFormLabel
                  label={`Set DNS server ${index + 1} IP address`}
                  isRequired={true}
                />
              }
              value={dns}
              invalid={!validDetail[index].result && !isEmpty(dns)}
              invalidText={validDetail[index].text}
              placeholder="Enter the DNS server IP..."
              onChange={event => {
                const text = event.target.value;
                handleDNSChange(index, text);
              }}
            />
          </li>
        ))}
      </ul>
    </CreateTearsheetStep>
  );
};

DNSStep.propTypes = {
  DNS: PropTypes.array.isRequired,
  handleDNSChange: PropTypes.func.isRequired,
  handleDNSResize: PropTypes.func.isRequired
};

export default DNSStep;
