import React from 'react';
import moment from 'moment-timezone';

const timezonesRaw = require('./timezones.json');
const timezones = Object.values(timezonesRaw.zones);
export const timezoneInfo = timezones
  .map(val => ({
    offset: moment().tz(val.name).utcOffset(),
    name: val.name
  }))
  .sort((a, b) => {
    if (a.offset - b.offset === 0) return a.name.localeCompare(b.name);
    return a.offset - b.offset;
  });

export const offsetToString = offset => {
  const sign = offset >= 0 ? '+' : '-';
  if (offset < 0) offset = -offset;
  return `UTC${sign}${Math.trunc(offset / 60)}:${('00' + (offset % 60)).slice(
    -2
  )}`;
};

export class TimezonePicker extends React.PureComponent {
  constructor({ handleTimezoneChange }) {
    super();
    this.handleTimezoneChange = handleTimezoneChange;

    this.state = {
      centers: timezones.map(val => ({
        x: (180 + val.long) / 360,
        y: (90 - val.lat) / 180,
        name: val.name
      })),
      lastCenter: {},
      mapAxisX: '',
      mapAxisY: '',
      timeLocale: '',
      offset: ''
    };
    this.onMouseMove.bind(this);
    this.onClick.bind(this);

    this.mapRef = React.createRef();
  }

  renderZones() {
    return this.state.centers.map(val => {
      const isCurrent = val.name === this.props.timezone?.name;
      const isHover = val.name === this.state?.name;
      return (
        <span
          style={{
            top: `calc(${val.y * 100}% - 2.5px)`,
            left: `calc(${val.x * 100}% - 3px)`,
            background: isCurrent ? 'red' : isHover ? '#F99' : 'white',
            border: isCurrent || isHover ? 'none' : undefined,
            zIndex: isCurrent || isHover ? 1 : 0
          }}
          key={val.name}
          className="timezone-dot"
          data-test={val.name}
        />
      );
    });
  }

  distSqr(x, y, px, py) {
    const dx = x - px;
    const dy = y - py;
    return dx * dx + dy * dy;
  }

  changeCenter(center) {
    const lastCenter = this.state.lastCenter;
    if (center === lastCenter) {
      return;
    }
    if (lastCenter) {
      this.deactivate(lastCenter);
    }

    this.activate(center);
  }

  activate(tz) {
    const m = moment().tz(tz.name);
    this.setState({
      name: tz.name,
      timeLocale: `${m.format('hh:mm a ')} ${m.zoneAbbr()} ${m.utcOffset()}`,
      offset: m.utcOffset(),
      lastCenter: tz,
      mapAxisX: `calc(${tz.x * 100}% + 0px)`,
      mapAxisY: `calc(${tz.y * 100}% + 0px)`
    });
  }

  deactivate() {}

  onMouseMove(e) {
    const rect = this.mapRef.current.getBoundingClientRect();
    const win = this.mapRef.current.ownerDocument.defaultView;
    const offsetTop = rect.top + win.pageYOffset;
    const offsetLeft = rect.left + win.pageXOffset;
    const x = e.pageX - offsetLeft;
    const y = e.pageY - offsetTop;
    const px = x / this.mapRef.current.offsetWidth;
    const py = y / this.mapRef.current.offsetHeight;
    let dist;
    let closestDist = 100;
    let closestCenter;

    this.state.centers.forEach(tz => {
      if (tz.isHidden) return;
      dist = this.distSqr(tz.x, tz.y, px, py);
      if (dist < closestDist) {
        closestCenter = tz;
        closestDist = dist;
      }
    });

    if (closestCenter) {
      this.changeCenter(closestCenter);
    }
  }

  onClick() {
    this.handleTimezoneChange({
      name: this.state.name,
      offset: this.state.offset
    });
  }

  render() {
    return (
      <>
        <div className="timezone-picker-description">
          World map:
          {typeof this.state.offset === 'number' &&
            ' ' + offsetToString(this.state.offset) + ' ' + this.state.name}
        </div>
        <div className="timezonePicker" ref={this.mapRef}>
          <div
            className="worldMap"
            onMouseMove={e => this.onMouseMove(e)}
            onClick={e => this.onClick(e)}
          >
            <div className="map-axis-x" style={{ left: this.state.mapAxisX }} />
            <div className="map-axis-y" style={{ top: this.state.mapAxisY }} />
            {this.renderZones()}
          </div>
        </div>
      </>
    );
  }
}
