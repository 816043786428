import React from 'react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import { TextInput, PasswordInput } from '@carbon/react';
import PropTypes from 'prop-types';
import ipaddr from 'ipaddr.js';
import { prompts, placeholders, rules, options } from './utils';
import CreateFormLabel from '@components/CreateFormLabel';
import { isEmpty } from 'lodash';

const ClusterRoleStep = ({ roleSettings, handleRoleSettingsChange }) => {
  const canSubmit = options.every(option =>
    rules[option].every(rule => rule.validate(roleSettings[option]))
  );

  return (
    <CreateTearsheetStep
      title="Role Common Configuration"
      hasFieldset={false}
      disableSubmit={!canSubmit}
    >
      <ul>
        {options.map(option => {
          const isValid = rules[option].every(rule =>
            rule.validate(roleSettings[option])
          );

          const invalidText =
            rules[option].find(rule => !rule.validate(roleSettings[option]))
              ?.text || '';

          const handleOnBlurEvent = evt => {
            if (option !== 'mgmtCIDR' || !isValid) return;
            const text = evt.target.value;
            const newCIDR = `${ipaddr.IPv4.networkAddressFromCIDR(
              text
            ).toString()}/${text.split('/')[1]}`;
            handleRoleSettingsChange(option, newCIDR);
          };

          const handleTextInputChange = evt => {
            const text = evt.target.value;
            handleRoleSettingsChange(option, text);
          };

          const textInputProps = {
            id: `${option}-input`,
            value: roleSettings[option],
            placeholder: placeholders[option],
            labelText: <CreateFormLabel {...prompts[option]} />,
            invalid: !isValid && !isEmpty(roleSettings[option]),
            invalidText,
            onBlur: handleOnBlurEvent,
            onChange: handleTextInputChange
          };

          return (
            <li className="role-ul-item" key={option}>
              {option === 'secretSeed' ? (
                <PasswordInput {...textInputProps} />
              ) : (
                <TextInput {...textInputProps} />
              )}
            </li>
          );
        })}
      </ul>
    </CreateTearsheetStep>
  );
};

ClusterRoleStep.protoTypes = {
  roleSettings: PropTypes.object.isRequired,
  handleRoleSettingsChange: PropTypes.func.isRequired
};

export default ClusterRoleStep;
