import React from 'react';
import {
  Checkbox,
  TextArea,
  Button,
  OrderedList,
  ListItem
} from '@carbon/react';
import PropTypes from 'prop-types';

const TermsComponent = ({ index, termText, onCheck, isAgree, onBtnClick }) => {
  return (
    <>
      <TextArea
        className="agreement-textarea"
        rows={20}
        size="lg"
        readOnly
        value={termText}
        labelText=""
      />
      <div className="agreement_wrapper">
        {index === 0 && (
          <Button data-testid="license-btn" onClick={onBtnClick}>
            Next
          </Button>
        )}
        {index === 1 && (
          <div className="agreement_sentences">
            By choosing &quot;I agree&quot; , you agree that
            <OrderedList className="agreement_order-list">
              <ListItem>
                You have had the opportunity to review the terms of licenses
                presented above and
              </ListItem>
              <ListItem>such terms govern this transaction.</ListItem>
            </OrderedList>
            <Checkbox
              checked={isAgree}
              id="checkbox-agree"
              onChange={(_evt, { checked }) => {
                onCheck(checked);
              }}
              labelText="I agree."
            />
          </div>
        )}
      </div>
    </>
  );
};

TermsComponent.defaultProps = {
  isAgree: false,
  onCheck: () => {},
  onBtnClick: () => {}
};

TermsComponent.propTypes = {
  index: PropTypes.number.isRequired,
  termText: PropTypes.string.isRequired,
  isAgree: PropTypes.bool,
  onCheck: PropTypes.func,
  onBtnClick: PropTypes.func
};

export default TermsComponent;
