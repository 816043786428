import { isEmpty } from 'lodash';

export const roleOptions = {
  control: ['mgmtIF', 'storIF', 'storIFBackend'],
  compute: ['mgmtIF', 'providerIF', 'overlayIF', 'storIF', 'storIFBackend'],
  storage: ['mgmtIF', 'storIF', 'storIFBackend'],
  'control-converged': [
    'mgmtIF',
    'providerIF',
    'overlayIF',
    'storIF',
    'storIFBackend'
  ],
  'edge-core': ['mgmtIF', 'providerIF', 'overlayIF', 'storIF', 'storIFBackend'],
  moderator: ['mgmtIF', 'storIF', 'storIFBackend']
};

const defaultRoleSettings = {
  mgmtIF: {},
  providerIF: {},
  overlayIF: {},
  storIF: {},
  storIFBackend: {}
};

export const createDefaultRoleSettings = (role, oldSettings = undefined) =>
  roleOptions[role].reduce(
    (dict, option) => ({
      ...dict,
      [option]: oldSettings?.[option] || defaultRoleSettings[option]
    }),
    {}
  );

export const prompts = {
  mgmtIF: {
    label: 'Set management interface',
    isRequired: true,
    infoLabel:
      'An interface that gives you access to CubeOS management Web interface, CLI & root shell.\nThe IP address of the management interface must be specified.'
  },
  providerIF: {
    label: 'Set provider interface',
    isRequired: true,
    infoLabel: 'An interface which comes with internet connection.'
  },
  overlayIF: {
    label: 'Set overlay interface',
    isRequired: true,
    infoLabel:
      'An interface for Software-Defined-Network(SDN) and network virtualization.'
  },
  storIF: {
    label: 'Set storage frontend interface',
    isRequired: true,
    infoLabel:
      'An interface for IAAS connection, read/write data over Software-Defined-Storage(SDS).'
  },
  storIFBackend: {
    label: 'Set storage backend interface (optional)',
    infoLabel:
      'A separated interface for Data replication of Software-Defined-Storage(SDS).'
  }
};

const commonIFrules = (allowEmpty = false, requireIP = false) => [
  {
    text: 'Please choose a network interface',
    validate: (item, selectableIFs) =>
      (allowEmpty && isEmpty(item)) ||
      !!selectableIFs?.find(IF => item?.id === IF.id)
  },
  {
    text: 'The network interface requires IP configuration',
    validate: (item, selectableIFs) => {
      if (!requireIP) return true;
      const IF = selectableIFs?.find(IF => item.id === IF.id);
      return !!IF.IPAddr;
    }
  }
];

export const rules = {
  mgmtIF: commonIFrules(false, true),
  providerIF: commonIFrules(),
  overlayIF: commonIFrules(),
  storIF: commonIFrules(),
  storIFBackend: commonIFrules(true)
};
