import React from 'react';
import { Modal, CodeSnippet, Link } from '@carbon/react';
import { getClusterShortId } from '@components/utils';
import PropTypes from 'prop-types';

const GetSnapshotModal = ({ isOpened, nodeName, clusterInfo, onClose }) => {
  const clusterName = clusterInfo?.name || 'unknown';
  const clusterShortId = getClusterShortId(clusterInfo);
  const url = encodeURI(
    `${window.location.protocol}//${window.location.host}/api/snapshot/getSnapshot?id=${clusterShortId}` +
      (nodeName ? `&node=${nodeName}` : '')
  );
  const curlUrl =
    `curl "${url}" -o ` +
    (nodeName ? `"${nodeName}.snapshot"` : `"${clusterName}.zip"`);
  const wgetUrl = `wget --content-disposition "${url}"`;
  return (
    <Modal
      className="cube-get-snapshot-modal"
      open={isOpened}
      modalHeading={`Get ${nodeName ? 'Node' : 'Cluster'} Snapshot`}
      passiveModal
      onRequestClose={() => onClose(false)}
    >
      <div className="modal-content">
        <ul>
          <p>
            You can get your snapshot with the following{' '}
            <Link href={url}>URL</Link>:
          </p>
          <li>
            <strong>URL:</strong>
            <CodeSnippet type="multi" wrapText light>
              {url}
            </CodeSnippet>
          </li>
          <hr />
          <p>You can also get your snapshot with the following commands:</p>
          <li>
            <strong>curl:</strong>
            <CodeSnippet type="multi" wrapText light>
              {curlUrl}
            </CodeSnippet>
          </li>
          <li>
            <strong>wget:</strong>
            <CodeSnippet type="multi" wrapText light>
              {wgetUrl}
            </CodeSnippet>
          </li>
        </ul>
      </div>
    </Modal>
  );
};

GetSnapshotModal.defaultProps = {
  nodeName: null,
  clusterName: 'unknown'
};

GetSnapshotModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  nodeName: PropTypes.string,
  clusterName: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

export default GetSnapshotModal;
