import React, { useState } from 'react';
import { Button } from '@carbon/react';
import { DonutChart } from '@carbon/charts-react';
import { View, ViewOff } from '@carbon/icons-react';
import { roleOptions } from '@components/NodeWizard/components/NodeRoleStep';
import { isBoolean, isEmpty } from 'lodash';
import { roleColors } from '@content/utils';
import { getClusterShortId } from '@components/utils';

const ClusterDetailCard = ({ clusterDetail }) => {
  const { clusterInfo, clusterConfig, nodeData } = clusterDetail;
  const clusterShortId = getClusterShortId(clusterInfo);
  const chartData = Object.keys(roleOptions).reduce((arr, role) => {
    if (!nodeData?.filter(config => config.role == role).length) return arr;
    arr.push({
      group: role,
      value: nodeData.filter(config => config.role == role).length
    });
    return arr;
  }, []);
  const [hideSecretSeed, setHideSecretSeed] = useState(true);

  return (
    <div className="cluster-detail-card">
      <div className="cluster-donut">
        <DonutChart
          data={chartData}
          options={{
            resizable: true,
            toolbar: false,
            data: {
              loading: chartData.length === 0
            },
            getFillColor: field => roleColors[field],
            donut: {
              center: {
                label: 'Node'
              }
            }
          }}
        />
      </div>
      <ul>
        <li>
          <strong>Cluster ID </strong>: {clusterShortId}
        </li>
        {['DNS', 'timezone', 'roleSettings', 'HA'].map(key => {
          if (isEmpty(clusterConfig[key]) && !isBoolean(clusterConfig[key]))
            return;
          if (key === 'roleSettings') {
            return (
              <React.Fragment key={key}>
                <li key="secretSeed">
                  <strong>Secret Seed </strong>:{' '}
                  {hideSecretSeed
                    ? '*'.repeat(clusterConfig.roleSettings.secretSeed.length)
                    : clusterConfig.roleSettings.secretSeed}
                  <Button
                    iconDescription={
                      hideSecretSeed ? 'Show Secret Seed' : 'Hide Secret Seed'
                    }
                    renderIcon={hideSecretSeed ? View : ViewOff}
                    hasIconOnly
                    kind="ghost"
                    size="sm"
                    className="secret-seed-button"
                    onClick={() => setHideSecretSeed(val => !val)}
                  />
                </li>
              </React.Fragment>
            );
          }
          if (key === 'HA') {
            return (
              <React.Fragment key={key}>
                <li key="HA">
                  <strong>HA </strong>:{' '}
                  {clusterConfig.HA ? 'High Availability' : 'Standalone'}
                </li>
                {clusterConfig.HA && (
                  <>
                    <li key="virtualHostname">
                      <strong>Control Virtual Hostname </strong>:{' '}
                      {clusterConfig.HASettings?.virtualHostname}
                    </li>
                    <li key="virtualIP">
                      <strong>Control Virtual IP </strong>:{' '}
                      {clusterConfig.HASettings?.virtualIP}
                    </li>
                  </>
                )}
              </React.Fragment>
            );
          }
          return (
            <li key={key}>
              <strong>{key[0].toUpperCase() + key.slice(1)} </strong>:{' '}
              {key === 'timezone'
                ? clusterConfig[key].name
                : key === 'DNS'
                ? clusterConfig[key].join(', ')
                : clusterConfig[key]}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ClusterDetailCard;
