import React from 'react';
import { ToastNotification, ListItem, OrderedList } from '@carbon/react';

import {
  findDuplicatedHostname,
  findDuplicatedIF,
  findInvalidVirtualHostname,
  findInvalidVirtualIP,
  isRoleDistributionValid,
  hasComputeRole,
  hasModeratorRole,
  commonClusterNotificationProps as commonNotificationProps
} from '@components/utils';

const ClusterNotification = ({ clusterConfig, tableData }) => {
  const duplicatedHostname = findDuplicatedHostname(tableData);
  const duplicatedIF = findDuplicatedIF(tableData);
  const invalidVirtualhostname = findInvalidVirtualHostname(
    clusterConfig,
    tableData
  );
  const invalidVirtualIP = findInvalidVirtualIP(clusterConfig, tableData);
  const hasCompute = hasComputeRole(tableData);
  const hasModerator = hasModeratorRole(tableData);
  const isRoleValid = isRoleDistributionValid(clusterConfig, tableData);

  return (
    <div className="cluster-notification-div">
      {!!duplicatedHostname && (
        <ToastNotification
          {...commonNotificationProps}
          statusIconDescription="hostname error"
          title="Hostname Error"
          subtitle={`Hostnames should not be duplicated, but ${duplicatedHostname} is used more than once.`}
          kind="error"
        />
      )}
      {!!duplicatedIF && (
        <ToastNotification
          {...commonNotificationProps}
          statusIconDescription="network interface error"
          subtitle={`${duplicatedIF.config1.IFName} of ${duplicatedIF.config1.hostname} and ${duplicatedIF.config2.IFName} of ${duplicatedIF.config2.hostname} share the same IP address ${duplicatedIF.IPAddr}`}
          title="Network Interface Error"
          kind="error"
        />
      )}
      {tableData.length > 0 && !hasCompute && (
        <ToastNotification
          {...commonNotificationProps}
          statusIconDescription="compute role error"
          title="Compute Role Error"
          subtitle="Each cluster should have at least one compute, control-converged, or edge-core node."
          kind="error"
        />
      )}
      {clusterConfig.HA && !!invalidVirtualhostname && (
        <ToastNotification
          {...commonNotificationProps}
          statusIconDescription="control virtual hostname error"
          title="Control Virtual Hostname Error"
          subtitle={`Hostnames should not be duplicated, but ${invalidVirtualhostname} is used by the control virtual hostname and other nodes.`}
          kind="error"
        />
      )}
      {clusterConfig.HA && !!invalidVirtualIP && (
        <ToastNotification
          {...commonNotificationProps}
          statusIconDescription="control virtual IP error"
          title="Control Virtual IP Error"
          subtitle={`The control virtual IP ${invalidVirtualIP.IPAddr} is used by ${invalidVirtualIP.IFName} of ${invalidVirtualIP.hostname}`}
          kind="error"
        />
      )}
      {clusterConfig.HA && tableData.length > 0 && !isRoleValid && (
        <ToastNotification
          {...commonNotificationProps}
          statusIconDescription="high availability error"
          title="High Availability Error"
          subtitle="In high availability, the nodes must satisfy one of the following configurations:"
          kind="error"
        >
          <OrderedList
            style={{
              marginTop: '-0.5rem',
              marginBottom: '1rem',
              marginLeft: '1.5rem'
            }}
          >
            <ListItem>
              <strong>Normal configuration</strong>: There are 3 control and
              control-converged nodes without any edge-core or moderator nodes.
            </ListItem>
            <ListItem>
              <strong>Edge configuration</strong>: There are &quot;3
              edge-cores&quot; or &quot;2 edge-cores + 1 moderator&quot; without
              any control or control-converged nodes.
            </ListItem>
          </OrderedList>
        </ToastNotification>
      )}
      {!clusterConfig.HA && tableData.length > 0 && !isRoleValid && (
        <ToastNotification
          {...commonNotificationProps}
          statusIconDescription="standalone error"
          title="Standalone Error"
          subtitle="In standalone, there is a unique control, control-converged, or edge-core."
          kind="error"
        />
      )}
      {!clusterConfig.HA && hasModerator && (
        <ToastNotification
          {...commonNotificationProps}
          statusIconDescription="moderator error"
          title="Moderator Error"
          subtitle="In standalone, moderators are not allowed. If you need a moderator, please switch to high availability."
          kind="error"
        />
      )}
    </div>
  );
};

export default ClusterNotification;
