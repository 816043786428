import { isEmpty } from 'lodash';

/**
 * This function validates whether nodeConfig is valid or not.
 * @param {object} nodeConfig
 */
const checkNodeConfigValid = nodeConfig => {
  for (const attr of ['id', 'hostname', 'defaultGateway', 'role']) {
    if (!(attr in nodeConfig) || typeof nodeConfig[attr] !== 'string') {
      throw new SyntaxError(`${attr} of nodeConfig is invalid.`);
    }
  }
  for (const attr of ['initIFs', 'bondIFs', 'vlanIFs']) {
    if (!(attr in nodeConfig) || !Array.isArray(nodeConfig[attr])) {
      throw new SyntaxError(`${attr} of nodeConfig is invalid.`);
    }
    const IFs = nodeConfig[attr];
    for (const IF of IFs) {
      if (typeof IF !== 'object') {
        throw new SyntaxError(`${attr} of nodeConfig is invalid`);
      }
      for (const option of ['id', 'type', 'name']) {
        if (!(option in IF) || typeof IF[option] !== 'string') {
          throw new SyntaxError(
            `${option} of ${attr} in nodeConfig is invalid.`
          );
        }
      }
      if (!('enabled' in IF) || typeof IF.enabled !== 'boolean') {
        throw new SyntaxError(`enabled of ${attr} in nodeConfig is invalid.`);
      }
    }
  }
};

/**
 * This function validates whether clusterConfig is valid or not.
 * @param {object} clusterConfig
 */
const checkClusterConfigValid = clusterConfig => {
  if (typeof clusterConfig !== 'object') {
    throw new SyntaxError(
      `clusterConfig is expected to be an object, but it is ${typeof clusterConfig} instead`
    );
  }

  // DNS validation
  if (!('DNS' in clusterConfig) || !Array.isArray(clusterConfig.DNS)) {
    throw new SyntaxError('The DNS of clusterConfig is invalid.');
  }
  for (const DNS of clusterConfig.DNS) {
    if (typeof DNS !== 'string') {
      throw new SyntaxError('The DNS of clusterConfig is invalid.');
    }
  }

  // timezone validation
  if (
    !('timezone' in clusterConfig) ||
    typeof clusterConfig.timezone !== 'object'
  ) {
    throw new SyntaxError('The timezone of clusterConfig is invalid.');
  }
  const timezone = clusterConfig.timezone;
  if (!('name' in timezone) || typeof timezone.name !== 'string') {
    throw new SyntaxError('The timezone name of clusterConfig is invalid.');
  }
  if (!('offset' in timezone) || typeof timezone.offset !== 'number') {
    throw new SyntaxError('The timezone offset of clusterConfig is invalid.');
  }

  // roleSettings validation
  if (
    !('roleSettings' in clusterConfig) ||
    typeof clusterConfig.timezone !== 'object'
  ) {
    throw new SyntaxError('The roleSettings of clusterConfig is invalid.');
  }
  const roleSettings = clusterConfig.roleSettings;
  for (const option of ['extIP', 'region', 'secretSeed', 'mgmtCIDR']) {
    if (!(option in roleSettings) || typeof roleSettings[option] !== 'string') {
      throw new SyntaxError(
        `${option} of roleSettings in clusterConfig is invalid.`
      );
    }
  }

  // HA validation
  if (!('HA' in clusterConfig) || typeof clusterConfig.HA !== 'boolean') {
    throw new SyntaxError('The HA of clusterConfig is invalid.');
  }
  if (
    !('HASettings' in clusterConfig) ||
    typeof clusterConfig.HASettings !== 'object'
  ) {
    throw new SyntaxError('The HASettings of clusterConfig is invalid.');
  }
  const HASettings = clusterConfig.HASettings;
  if (clusterConfig.HA) {
    for (const option of ['virtualIP', 'virtualHostname']) {
      if (!(option in HASettings) || typeof HASettings[option] !== 'string') {
        throw new SyntaxError(
          `${option} of HASettings in clusterConfig is invalid.`
        );
      }
    }
  } else {
    if (!isEmpty(HASettings))
      throw new SyntaxError('HASettings should be empty');
  }
};
/**
 * This function can validate whether clusterInfo is valid or not.
 * @param {object} clusterInfo
 */
const checkClusterInfoValid = clusterInfo => {
  if (typeof clusterInfo !== 'object') {
    throw new SyntaxError(
      `clusterInfo is expected to be an object, but it is ${typeof clusterInfo} instead`
    );
  }

  // cluster name validation
  if (!('name' in clusterInfo) || typeof clusterInfo.name !== 'string') {
    throw new SyntaxError('The name of clusterInfo is invalid.');
  }
  // cluster id validation
  if (!('id' in clusterInfo) || typeof clusterInfo.id !== 'string') {
    throw new SyntaxError('The id of clusterInfo is invalid.');
  }
};

/**
 * This function can validate whether clusterDetail is valid. It will throw an error if it's invalid.
 * or not.
 * @description Note that this is just a "rough" validation. It ensures that the
 * imported json file will not crash the snapshot generator, but it does not
 * guarantee that each field is valid on the wizard UI. For example, it only
 * checks whether DNS is specified, but it does not check whether
 * each DNS follows the IPv4 rule.
 * @param {object} clusterDetail
 */
export const checkClusterDetailValid = clusterDetail => {
  checkClusterInfoValid(clusterDetail?.clusterInfo);
  checkClusterConfigValid(clusterDetail?.clusterConfig);
  if (
    !('nodeData' in clusterDetail) ||
    !Array.isArray(clusterDetail.nodeData)
  ) {
    throw new SyntaxError('nodeData is invalid');
  }
  for (const nodeConfig of clusterDetail.nodeData) {
    checkNodeConfigValid(nodeConfig);
  }
};
