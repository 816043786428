import React, { useEffect, useState } from 'react';
import { CreateTearsheet } from '@carbon/ibm-products';
import { cloneDeep, isEmpty } from 'lodash';
import { genRandomChars } from '@components/utils';
import {
  LicenseStep,
  NameStep,
  DNSStep,
  TimezoneStep,
  ClusterRoleStep,
  HAStep,
  createDefaultHASettings
} from './components';

const blockClass = `cube-create-config-wizard`;

const ClusterWizard = ({
  selectedData,
  onClose,
  isOpened,
  onSubmit,
  clusterInfo
}) => {
  const [name, setName] = useState(`Cluster_${genRandomChars(6)}`);
  const [DNS, setDNS] = useState(['8.8.8.8']);
  const [timezone, setTimezone] = useState({
    name: 'Asia/Taipei',
    offset: 480
  });

  const [roleSettings, setRoleSettings] = useState({
    extIP: '',
    region: 'RegionOne',
    secretSeed: `${genRandomChars(6)}`,
    mgmtCIDR: '10.254.0.0/16'
  });

  const [HA, setHA] = useState(false);
  const [HASettings, setHASettings] = useState({});

  const isEditWizard = !isEmpty(selectedData);

  useEffect(() => {
    setName(clusterInfo?.name || `Cluster_${genRandomChars(6)}`);
    setDNS(selectedData?.DNS || ['8.8.8.8']);
    setTimezone(
      selectedData?.timezone || {
        name: 'Asia/Taipei',
        offset: 480
      }
    );
    setRoleSettings(
      selectedData?.roleSettings || {
        extIP: '',
        region: 'RegionOne',
        secretSeed: `${genRandomChars(6)}`,
        mgmtCIDR: '10.254.0.0/16'
      }
    );
    const newHA = selectedData?.HA || false;
    setHA(newHA);
    setHASettings(selectedData?.HASettings || createDefaultHASettings(newHA));
  }, [selectedData, clusterInfo, isOpened]);

  const handleNameChange = value => {
    setName(value);
  };

  const handleDNSChange = (index, IP) => {
    let newDNS = cloneDeep(DNS);
    newDNS[index] = IP;
    setDNS(newDNS);
  };

  const handleDNSResize = num => {
    let newDNS = cloneDeep(DNS);
    while (newDNS.length < num) newDNS.push('');
    newDNS.length = num;
    setDNS(newDNS);
  };

  const handleTimezoneChange = newTimezone => setTimezone(newTimezone);

  const handleRoleSettingsChange = (option, value) => {
    setRoleSettings({ ...roleSettings, [option]: value });
  };

  const handleHAChange = isEnabled => {
    setHA(isEnabled);
    setHASettings(createDefaultHASettings(isEnabled));
  };

  const handleHASettingsChange = (option, value) => {
    setHASettings({ ...HASettings, [option]: value });
  };

  return (
    <div>
      <style>{`.${blockClass} { opacity: 0 }`};</style>
      <CreateTearsheet
        id={`${blockClass}-cluster`}
        className={blockClass}
        submitButtonText="Save"
        cancelButtonText="Cancel"
        backButtonText="Back"
        nextButtonText="Next"
        title="Cluster Snapshot Configuration"
        open={isOpened}
        onClose={() => onClose()}
        onRequestSubmit={() => {
          onSubmit({
            clusterConfig: {
              DNS,
              timezone,
              roleSettings,
              HA,
              HASettings
            },
            clusterInfo: {
              id: clusterInfo?.id || 'unknown',
              name
            }
          });
        }}
      >
        <LicenseStep isEditWizard={isEditWizard} />
        <NameStep name={name} handleNameChange={handleNameChange} />
        <DNSStep
          DNS={DNS}
          handleDNSChange={handleDNSChange}
          handleDNSResize={handleDNSResize}
        />
        <TimezoneStep
          timezone={timezone}
          handleTimezoneChange={handleTimezoneChange}
        />
        <ClusterRoleStep
          roleSettings={roleSettings}
          handleRoleSettingsChange={handleRoleSettingsChange}
        />
        <HAStep
          HA={HA}
          HASettings={HASettings}
          handleHAChange={handleHAChange}
          handleHASettingsChange={handleHASettingsChange}
        />
      </CreateTearsheet>
    </div>
  );
};

export default ClusterWizard;
