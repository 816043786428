import React, { useEffect, useState } from 'react';
import {
  Content,
  Theme,
  Modal,
  OrderedList,
  ListItem,
  Button
} from '@carbon/react';
import UIHeader from '@components/UIHeader';
import LandingPage from '@content/LandingPage';
import ClusterPage from '@content/ClusterPage';
import { v4 as uuidv4 } from 'uuid';
import '@src/app.scss';

const App = () => {
  const [clustersInfo, setClustersInfo] = useState([]);
  const [currentId, setCurrentId] = useState(undefined);
  const [isCreateCluster, setIsCreateCluster] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState({});

  const handleCreateCluster = ({ clusterConfig, clusterInfo }) => {
    const newClusterId = uuidv4();
    clusterInfo = { ...clusterInfo, id: newClusterId };
    localStorage.setItem(
      `${newClusterId}-cluster`,
      JSON.stringify(clusterConfig)
    );
    setClustersInfo([...clustersInfo, clusterInfo]);
    setCurrentId(newClusterId);
  };

  const handleImportCluster = clusterDetail => {
    const { nodeData, clusterInfo, clusterConfig } = clusterDetail;
    const newClusterId = uuidv4();
    setClustersInfo([
      ...clustersInfo,
      {
        ...clusterInfo,
        id: newClusterId
      }
    ]);
    localStorage.setItem(
      `${newClusterId}-cluster`,
      JSON.stringify(clusterConfig)
    );

    // assign new id for each node
    const newNodeData = nodeData.map(config => ({
      ...config,
      id: uuidv4()
    }));

    localStorage.setItem(`${newClusterId}-nodes`, JSON.stringify(newNodeData));
  };

  const handleEditInfo = clusterInfo => {
    const newClustersInfo = clustersInfo.map(info =>
      info.id === clusterInfo.id ? clusterInfo : info
    );
    setClustersInfo(newClustersInfo);
  };

  const handleDeleteAllClusters = () => {
    setDeleteModalOpen(true);
    setDeleteInfo(clustersInfo);
  };

  const handleDeleteCluster = deleteId => {
    const deleteInfo = clustersInfo.find(info => info.id === deleteId) || {};
    setDeleteModalOpen(true);
    setDeleteInfo(deleteInfo);
  };

  const handleDeleteAction = () => {
    const _handleDeleteAllClusters = () => {
      localStorage.clear();
      setCurrentId(undefined);
      setClustersInfo([]);
    };

    const _handleDeleteCluster = deleteId => {
      const newClustersInfo = clustersInfo.filter(info => info.id !== deleteId);
      if (currentId) setCurrentId(undefined);
      setClustersInfo(newClustersInfo);
      localStorage.removeItem(`${deleteId}-cluster`);
      localStorage.removeItem(`${deleteId}-nodes`);
    };

    if (Array.isArray(deleteInfo)) _handleDeleteAllClusters();
    else _handleDeleteCluster(deleteInfo.id);
  };

  useEffect(() => {
    const newClustersInfo =
      JSON.parse(localStorage.getItem('clustersInfo')) || [];
    setClustersInfo(newClustersInfo);
  }, []);

  useEffect(() => {
    localStorage.setItem('clustersInfo', JSON.stringify(clustersInfo));
  }, [clustersInfo]);

  return (
    <>
      <Theme theme="g90">
        <UIHeader
          currentId={currentId}
          clustersInfo={clustersInfo}
          setCurrentId={setCurrentId}
          setIsCreateCluster={setIsCreateCluster}
          handleDeleteAllClusters={handleDeleteAllClusters}
        />
      </Theme>
      <Content>
        {currentId ? (
          <ClusterPage
            key={currentId}
            clusterInfo={clustersInfo.find(info => info.id === currentId) || {}}
            isCreateCluster={isCreateCluster}
            setCurrentId={setCurrentId}
            setIsCreateCluster={setIsCreateCluster}
            handleCreateCluster={handleCreateCluster}
            handleEditInfo={handleEditInfo}
            handleDeleteCluster={handleDeleteCluster}
          />
        ) : (
          <LandingPage
            isCreateCluster={isCreateCluster}
            setIsCreateCluster={setIsCreateCluster}
            handleCreateCluster={handleCreateCluster}
            handleEditInfo={handleEditInfo}
            handleDeleteCluster={handleDeleteCluster}
            handleImportCluster={handleImportCluster}
            clustersInfo={clustersInfo}
            setCurrentId={setCurrentId}
          />
        )}
      </Content>
      <Modal
        modalHeading={`Are you sure you want to delete ${
          Array.isArray(deleteInfo) ? 'all the clusters' : deleteInfo.name
        }?`}
        open={deleteModalOpen}
        onRequestClose={() => setDeleteModalOpen(false)}
        passiveModal
      >
        {Array.isArray(deleteInfo) && (
          <OrderedList style={{ marginLeft: '1.5rem' }}>
            {deleteInfo.map(info => (
              <ListItem key={info.id}> {info.name} </ListItem>
            ))}
          </OrderedList>
        )}
        <p style={{ marginTop: '1rem' }}>
          This action will permanently delete this cluster configuration and
          cannot be undone.
        </p>
        <Button
          data-modal-primary-focus
          kind="secondary"
          onClick={() => {
            setDeleteModalOpen(false);
          }}
          aria-label="Cancel"
          style={{ marginTop: '2rem', marginRight: '0.5rem' }}
        >
          Cancel
        </Button>
        <Button
          kind="danger"
          onClick={() => {
            handleDeleteAction();
            setDeleteModalOpen(false);
          }}
          aria-label="Delete"
          style={{ marginTop: '2rem' }}
        >
          Delete
        </Button>
      </Modal>
    </>
  );
};

export default App;
