import React from 'react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import { TextInput } from '@carbon/react';
import PropTypes from 'prop-types';
import CreateFormLabel from '@components/CreateFormLabel';
import { invalidRules } from './utils';

const NameStep = ({ name, handleNameChange }) => {
  const isInvalid = !invalidRules.every(rule => rule.validate(name));
  const invalidText =
    invalidRules.find(rule => !rule.validate(name))?.text || '';

  return (
    <CreateTearsheetStep
      title="Set cluster name"
      hasFieldset={false}
      disableSubmit={isInvalid}
    >
      <TextInput
        id="wizard-cluster-name-input"
        labelText={
          <CreateFormLabel
            label="Set cluster name"
            isRequired
            infoLabel="Cluster name is used for cluster management on this snapshot generator. The configuration will not affect the settings on the Cube OS."
          />
        }
        value={name}
        placeholder="Set cluster name"
        onChange={evt => handleNameChange(evt.target.value)}
        invalid={isInvalid}
        invalidText={invalidText}
      />
    </CreateTearsheetStep>
  );
};

NameStep.protoTypes = {
  name: PropTypes.string.isRequired,
  handleNameChange: PropTypes.func.isRequired
};

export default NameStep;
