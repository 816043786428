import ipaddr from 'ipaddr.js';

export const createDefaultHASettings = isEnabled => {
  if (!isEnabled) return {};
  return { virtualIP: '', virtualHostname: '' };
};

export const hostnameRules = [
  {
    text: 'The field should not be blank',
    validate: (str = '') => str !== ''
  },
  {
    text: 'This should only compose by "0-9", "a-z" or "-"(hyphen)',
    validate: str => /^[0-9a-z-]*$/.test(str)
  },
  {
    text: 'This should not start with "-"',
    validate: str => !/^-/.test(str)
  },
  {
    text: 'This should not end with "-"',
    validate: str => !/-$/.test(str)
  },
  {
    text: 'This should be comprised of 1~63 characters',
    validate: str => 1 <= str.length && str.length <= 63
  },
  {
    text: 'The first character must be an alphabet',
    validate: str => /^[a-z]/.test(str)
  }
];

export const IPRules = [
  {
    text: 'The field should not be blank',
    validate: (str = '') => str !== ''
  },
  {
    text: 'This should be a valid IPv4 address',
    validate: str => ipaddr.IPv4.isValidFourPartDecimal(str)
  }
];
